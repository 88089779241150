import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {IPage} from '../interfaces/field.interface';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {EventRestService} from './event-rest.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  public showErrorPageEvents$: BehaviorSubject<string> = new BehaviorSubject<string>('LOADING');
  public events$: BehaviorSubject<IPage> = new BehaviorSubject<IPage>(null);

  constructor(private translate: TranslateService,
              private eventRestService: EventRestService,
              private toastr: ToastrService) {
  }

  public getEvents(searchTerm: string, page: number, f?: string, t?: string): void {
    this.showErrorPageEvents$.next('LOADING');
    this.eventRestService.getEvents(searchTerm, page, f, t).subscribe({
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA'));
        this.showErrorPageEvents$.next('ERROR_EVENTS');
      },
      next: (response) => {
        response ? this.events$.next(response) : this.events$.next(null);
        this.showErrorPageEvents$.next('CONTENT_EVENTS');
      }
    })
  }

}
