import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'appFilter'})
export class FilterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @returns {any[]}
   */
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(it => {
      if (it.name) {
        return it.name.toLocaleLowerCase().includes(searchText);
      }
      if (it.clientName) {
        return it.clientName.toLocaleLowerCase().includes(searchText);
      }
    });
  }
}
