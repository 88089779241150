import {Component, OnDestroy, OnInit} from '@angular/core';
import {GroupService} from '../../services/group.service';
import {Subscription} from 'rxjs';
import {GroupRestService} from '../../services/group-rest.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {IObject} from '../../interfaces/field.interface';
import {HelperService} from '../../services/helper.service';
import {AuthService} from '../../auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-group-overview',
  templateUrl: './group-overview.component.html'
})

export class GroupOverviewComponent implements OnInit, OnDestroy {
  public Subscriptions: Subscription[] = [];
  public groups: IObject[];
  public selectedGroup = {} as IObject;
  public showNoResults: boolean = false;
  public showDeleteModal = false;
  public allowDeletion: boolean = false;
  public allowCreation: boolean = false;
  public pageInfo: any = {
    currentPage: 0,
    searchTerm: '',
    totalElements: 0,
    totalPages: undefined,
    firstPage: undefined,
    lastPage: undefined,
  }

  constructor(public groupService: GroupService,
              public groupRestService: GroupRestService,
              public helperService: HelperService,
              private translate: TranslateService,
              private cookieService: CookieService,
              public route: ActivatedRoute,
              public router: Router,
              private authService: AuthService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.Subscriptions.push(this.authService.outline$.subscribe(res => {
      if (res) {
        this.allowDeletion = this.helperService.findObjectByName(res, 'GROUPS')?.allowDeletion;
        this.allowCreation = this.helperService.findObjectByName(res, 'GROUPS')?.allowCreation;
      }
    }));

    this.Subscriptions.push(this.route
      .queryParams
      .subscribe(params => {
        if (Object.keys(params).length !== 0) {
          this.pageInfo.currentPage = params.page ? parseInt(params.page) : 0;
          this.pageInfo.searchTerm = params.searchTerm ? params.searchTerm : '';
          this.cookieService.set('searchterm_groups', this.pageInfo.searchTerm);
          this.groupService.getGroups(this.pageInfo.searchTerm, this.pageInfo.currentPage);
        } else {
          this.cookieService.set('searchterm_groups', '');
          this.cookieService.set('show_groups', 'false');
          this.groupService.getGroups('', 0);
        }
      }));

    this.Subscriptions.push(this.groupService.groups$.subscribe(data => {
      if (data) {
        this.groups = data.content
        this.pageInfo.firstPage = data.first;
        this.pageInfo.lastPage = data.last;
        this.pageInfo.totalPages = data.totalPages;
        this.pageInfo.totalElements = data.totalElements;
        this.showNoResults = false;
      }
      if (data && data.empty) {
        this.showNoResults = true;
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.Subscriptions) {
      this.Subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

  public deleteGroup(id: string): void {
    this.groupRestService.deleteGroup(id).subscribe({
      next: () => {
        const index = this.groups.findIndex(group => this.helperService.getObjectByName(group.fields, 'id').value === id);
        if (index >= 0) {
          this.groups.splice(index, 1);
        }
        this.toastr.success(this.translate.instant('MESSAGES.SUCCESS.DATA'));
      },
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA_SAVE'));
      }
    })
  }

}
