import {AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IObject} from "../../interfaces/field.interface";
import {Subscription} from "rxjs";
import {HelperService} from "../../services/helper.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CookieService} from "ngx-cookie-service";
import {ImportLogService} from "../../services/import-log.service";

@Component({
  selector: 'app-import-log-overview',
  templateUrl: './import-log-overview.component.html'
})
export class ImportLogOverviewComponent implements OnInit, OnDestroy, AfterViewChecked {
  public pageInfo: any = {
    currentPage: 0,
    searchTerm: 'all',
    totalPages: undefined,
    firstPage: undefined,
    lastPage: undefined,
  };
  public logs: IObject[];
  public showLogInfo: boolean [] = [false];
  public showNoResults: boolean = false;
  public Subscriptions: Subscription[] = [];
  public fromTime: string = "";
  public toTime: string = "";
  public onlyError: boolean;
  public now = Date.now();

  @ViewChild('start') start: ElementRef;
  @ViewChild('end') end: ElementRef;


  constructor(public helperService: HelperService,
              public route: ActivatedRoute,
              public router: Router,
              private cookieService: CookieService,
              public importLogService: ImportLogService
  ) {
  }


  ngAfterViewChecked(): void {
    if (this.fromTime) {
      if (this.start && this.start.nativeElement) {
        this.start.nativeElement.value = this.helperService.parseDateToSlash(this.fromTime);
      }
    }
    if (this.toTime) {
      if (this.end && this.end.nativeElement) {
        this.end.nativeElement.value = this.helperService.parseDateToSlash(this.toTime);
      }
    }
  }


  ngOnInit(): void {
    this.Subscriptions.push(this.route
      .queryParams
      .subscribe(params => {
        if (Object.keys(params).length !== 0) {
          this.pageInfo.currentPage = params.page ? parseInt(params.page) : 0;
          this.pageInfo.searchTerm = params.searchTerm ? params.searchTerm : '';
          this.cookieService.set('searchterm_import_logs', this.pageInfo.searchTerm);
          if (params.fromTime && params.toTime) {
            this.fromTime = params.fromTime;
            this.toTime = params.toTime;
            this.importLogService.getImportLogs(this.pageInfo.searchTerm, this.pageInfo.currentPage, this.fromTime, this.toTime);
          } else if (params.fromTime && !params.toTime) {
            this.fromTime = params.fromTime;
            this.importLogService.getImportLogs(this.pageInfo.searchTerm, this.pageInfo.currentPage, this.fromTime);
          } else {
            this.importLogService.getImportLogs(this.pageInfo.searchTerm, this.pageInfo.currentPage);
          }
        } else {
          this.cookieService.set('searchterm_import_logs', '');
          this.cookieService.set('show_logs', 'false');
          this.importLogService.getImportLogs('all', 0);
        }

      }));

    this.Subscriptions.push(this.importLogService.logs$.subscribe(data => {
      if (data) {
        this.pageInfo.firstPage = data.first;
        this.pageInfo.lastPage = data.last;
        this.pageInfo.totalPages = data.totalPages;
        this.pageInfo.totalElements = data.totalElements;
        this.logs = data.content;
        this.showNoResults = false;
      }
      if (data && data.empty) {
        this.showNoResults = true;
      }
    }));
  }

  public logsByDate(page: number, searchterm: string | 'all'): void {
    this.now = Date.now();
    //Aktuelle Zeit, um das routing zu ermöglichen, ändert sich nämlich kein Wert, wird die Suche nicht mehr gefeuert, so wird die route nun getriggert, da der now Wert sich ändert. Dieser wird jedoch nicht verarbeitet
    if (this.fromTime && this.toTime) {
      this.router.navigate(['/import-log'], {
        queryParams: {
          searchTerm: searchterm ? searchterm : this.pageInfo.searchTerm,
          page: page ? page : this.pageInfo.currentPage,
          fromTime: this.helperService.parseDateToPoint(this.fromTime),
          toTime: this.helperService.parseDateToPoint(this.toTime),
          now: this.now
        }
      }).then();
    } else if (this.fromTime && !this.toTime) {
      this.router.navigate(['/import-log'], {
        queryParams: {
          searchTerm: searchterm ? searchterm : this.pageInfo.searchTerm,
          page: page ? page : this.pageInfo.currentPage,
          fromTime: this.helperService.parseDateToPoint(this.fromTime),
          now: this.now
        }
      }).then();
    } else {
      this.router.navigate(['/import-log'], {
        queryParams: {
          searchTerm: searchterm ? searchterm : this.pageInfo.searchTerm,
          page: page ? page : this.pageInfo.currentPage,
          now: this.now
        }
      }).then();
    }
  }


  public clear(): void {
    this.now = Date.now();
    //Aktuelle Zeit, um das routing zu ermöglichen, ändert sich nämlich kein Wert, wird die Suche nicht mehr gefeuert, so wird die route nun getriggert, da der now Wert sich ändert. Dieser wird jedoch nicht verarbeitet
    this.fromTime = undefined;
    this.toTime = undefined;
    this.pageInfo.searchTerm = 'all';
    this.onlyError = undefined;
    this.router.navigate(['/import-log'], {
      queryParams: {
        searchTerm: this.pageInfo.searchTerm,
        page: 0,
        now: this.now
      }
    }).then();
  }


  ngOnDestroy(): void {
    if (this.Subscriptions) {
      this.Subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }


  public changeOnlyError(checked: boolean): void {
    const type = checked ? 'error' : 'all';
    this.logsByDate(undefined, type);
  }

}
