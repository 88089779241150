import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import { IObject } from '../interfaces/field.interface';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AdminRestService {

  constructor(private cookieService: CookieService,
              private http: HttpClient) {
  }

  public uploadImport(file) {
    const uploadedFile = new FormData();
    uploadedFile.append('file', new Blob([file], {type: 'text/csv'}), file.name);
    uploadedFile.append('refreshToken', this.cookieService.get('refresh_token'));
    const url = `${environment.apiUrl}/user-administration/${environment.realmConfig}/import`;
    return this.http.post(url, uploadedFile);
  }

  public downloadFile(type: string) {
    return this.http.get(`${environment.apiUrl}/user-administration/${environment.realmConfig}/export/${type}`, {
      reportProgress: true,
      responseType: 'blob'
    })
  }

  public getHelpLink() {
    return this.http.get(`${environment.apiUrl}/user-administration/${environment.realmConfig}/help`, {responseType: 'text'})
  }

}
