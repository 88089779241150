<ng-container *ngIf="(userService.showErrorPageUsers$ | async) === 'ERROR_USERS'">
  <app-default-error-page></app-default-error-page>
</ng-container>

<ng-container *ngIf="(userService.showErrorPageUsers$ | async) === 'LOADING'">
  <app-default-loading-page></app-default-loading-page>
</ng-container>

<ng-container *ngIf="(userService.showErrorPageUsers$ | async) === 'CONTENT_USERS'">
  <div class="card-header-div">
    <h1 class="h1-header mr-4 flex-1">
      {{'USERS.HEADER' | translate}}
    </h1>
    <div class="flex justify-center flex-1">
      <app-search [type]="'users'"
                  (onSearchEmitter)="router.navigate(['/users'], {queryParams:  {searchTerm: $event, page: 0}}).then()"></app-search>
    </div>
    <div class="flex flex-1 justify-end gap-x-2">
      <button *ngIf="allowCreation" class="button-highlight" routerLink="new">
        {{'GENERAL.APPEND' | translate}}</button>
    </div>
  </div>

  <ng-container *ngIf="users && users.length >0">
    <div class="p-5">
      <div class="card-div">
        <div class="table-header-row">
          <div class="w-2/12 table-column">
            <h2> {{'GENERAL.LIST.ACTIVE' | translate}}</h2>
          </div>
          <div class="w-2/12 table-column">
            <h2 *ngIf="checkVisibility('LAST_NAME')"> {{'USERS.LASTNAME' | translate}}</h2>
          </div>
          <div class="w-2/12 table-column">
            <h2 *ngIf="checkVisibility('FIRST_NAME')"> {{'USERS.FIRSTNAME' | translate}}</h2>
          </div>
          <div class="w-2/12 table-column">
            <h2 *ngIf="checkVisibility('NAME')"> {{'USERS.USERNAME' | translate}}</h2>
          </div>
          <div class="w-2/12 table-column">
            <h2 *ngIf="checkVisibility('LAST_LOGIN')"> {{'USERS.LAST_LOGIN' | translate}}</h2>
          </div>
          <div class="w-2/12 table-column justify-center">
          </div>
          <div class="w-[5px]"></div>
        </div>
        <div class="overflow-y-auto max-h-[calc(100vh-17.5rem)]">
          <div *ngFor="let user of users; let last = last"
               class="table-row cursor-pointer border-b"
               [ngClass]="{'bg-slate-100': user?.marked,'rounded-b-md' : last}">
            <div class="w-2/12 table-column">
              <input type="checkbox" class="input-checkbox"
                     [checked]="helperService.getObjectByName(user.fields, 'active').value"
                     (change)="changeActive($event, user)"
                     [disabled]="helperService.getObjectByName(user.fields, 'active').readOnly">
            </div>
            <div class="w-2/12 table-column"
                 routerLink="{{(helperService.getObjectByName(user.fields, 'id')).value}}">
              <div *ngIf="checkVisibility('LAST_NAME')">
                {{(helperService.getObjectByName(user.fields, 'lastName')).value}}
              </div>
            </div>
            <div class="w-2/12 table-column"
                 routerLink="{{(helperService.getObjectByName(user.fields, 'id')).value}}">
              <div *ngIf="checkVisibility('FIRST_NAME')">
                {{(helperService.getObjectByName(user.fields, 'firstName')).value}}
              </div>
            </div>
            <div class="w-2/12 table-column"
                 routerLink="{{(helperService.getObjectByName(user.fields, 'id')).value}}">
              <div *ngIf="checkVisibility('NAME')">
                {{(helperService.getObjectByName(user.fields, 'username')).value}}
              </div>
            </div>
            <div class="w-2/12 table-column"
                 routerLink="{{(helperService.getObjectByName(user.fields, 'id')).value}}">
              <div *ngIf="checkVisibility('LAST_LOGIN')">
                {{helperService.getObjectByName(user.fields, 'lastLogin').value.includes('>') ?
                helperService.getObjectByName(user.fields, 'lastLogin').value : (helperService.getObjectByName(user.fields, 'lastLogin').value | date)}}
              </div>
            </div>
            <div class="w-2/12 table-column justify-end">
              <div
                class="group hover:text-highlight hover:bg-gray-200 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg sm:mx-0 sm:h-10 sm:w-10 relative">
                <svg routerLink="roles/{{helperService.getObjectByName(user.fields, 'id').value}}"
                     [queryParams]="{ username: helperService.getObjectByName(user.fields, 'username').value}"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="w-6 h-6"
                     data-tooltip-target="tooltip-bottom" data-tooltip-placement="bottom">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"/>
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                </svg>
                <div
                  class="group-hover:visible invisible absolute -top-2.5 -left-24 p-2 bg-gray-200 max-w-[12rem] rounded-lg text-center shadow text-highlight text-sm">
                  {{'USERS.VIEW_ROLES' | translate}}
                </div>
              </div>
              <div
                class="hover:text-highlight hover:bg-gray-200 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg sm:mx-0 sm:h-10 sm:w-10">
                <svg routerLink="{{(helperService.getObjectByName(user.fields, 'id')).value}}"
                     xmlns="http://www.w3.org/2000/svg"
                     class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"/>
                </svg>
              </div>
              <div *ngIf="allowDeletion"
                   class="hover:text-sso-red hover:bg-red-100 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg sm:mx-0 sm:h-10 sm:w-10">
                <svg (click)="selectedUser = user; showDeleteModal = true" xmlns="http://www.w3.org/2000/svg"
                     class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                </svg>
              </div>
               <div *ngIf="allowImpersonation"
                   class="group hover:text-highlight hover:bg-gray-200 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg sm:mx-0 sm:h-10 sm:w-10 relative">
                   <svg stroke="currentColor" stroke-width="4" (click)="impersonate((helperService.getObjectByName(user.fields, 'id')).value)" xmlns="http://www.w3.org/2000/svg" viewBox="-5 0 110 100.8" class="h-6 w-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M79.4,18.5c0-5.7-4.6-10.4-10.3-10.4h-25c-1.6,0-1.6,2.4,0,2.4h25c4.4,0,7.9,3.6,7.9,8,0,10.1-.1,15.6,0,18.6l-8.2-8.2c-1.1-1.1-2.8.6-1.7,1.7,11.1,11.1,10.4,10.6,11.1,10.6s0,.5,11.1-10.6c1.1-1.1-.6-2.8-1.7-1.7l-8.2,8.2c0-3,0-8.5,0-18.6Z"/>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.1,84.8c0,5.7,4.6,10.4,10.3,10.4h14.6c1.6,0,1.6-2.4,0-2.4h-14.6c-4.4,0-7.9-3.6-7.9-8,0-11.4.1-17.4,0-20.7,8.9,8.9,8.3,8.5,9,8.5,1.1,0,1.6-1.3.8-2-10.2-10.3-10.7-11.5-11.9-10.3-10.3,10.3-11.4,10.8-10.2,12,1.1,1.1,1.1.6,9.9-8.2,0,3.2,0,9.2,0,20.7Z"/>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M20.9,56.1h0c-7.1,0-14.1-1.9-20.2-5.6-.4-.3-.7-.7-.7-1.3v-.3c0-5.6,2.2-10.8,6.1-14.8,3.9-3.9,9.2-6.1,14.8-6.1h0c7.6,0,14.6,4.1,18.3,10.8,0,0,0,0,0,0,1.7,3.1,2.6,6.5,2.6,10.1h0v.3c0,.5-.3,1-.7,1.3-6.1,3.7-13,5.6-20.1,5.6ZM3,48.3c5.4,3.1,11.6,4.7,17.9,4.7h0c6.3,0,12.4-1.6,17.8-4.7,0-2.8-.8-5.6-2.2-8,0,0,0,0,0,0-3.1-5.7-9.2-9.3-15.7-9.3h0c-9.7,0-17.6,7.8-17.9,17.4ZM20.9,23.5c-6.5,0-11.8-5.3-11.8-11.8S14.4,0,20.9,0s11.8,5.3,11.8,11.8-5.3,11.8-11.8,11.8ZM20.9,3c-4.8,0-8.8,3.9-8.8,8.8s3.9,8.8,8.8,8.8,8.8-3.9,8.8-8.8-3.9-8.8-8.8-8.8Z"/>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M78.3,100.7h0c-7.1,0-14.1-1.9-20.2-5.6-.4-.3-.7-.7-.7-1.3v-.3c0-11.5,9.4-20.9,20.9-20.9h0c7.6,0,14.6,4.1,18.3,10.8,0,0,0,0,0,0,1.7,3.1,2.6,6.5,2.6,10.1h0v.3c0,.5-.3,1-.7,1.3-6.1,3.7-13,5.6-20.1,5.6ZM60.3,93c5.4,3.1,11.6,4.7,17.9,4.7h0c6.3,0,12.4-1.6,17.8-4.7,0-2.8-.8-5.6-2.2-8,0,0,0,0,0,0-3.1-5.7-9.2-9.3-15.7-9.3h0c-4.8,0-9.3,1.9-12.7,5.3-3.3,3.3-5.1,7.6-5.2,12.1ZM78.2,68.2c-6.5,0-11.8-5.3-11.8-11.8s5.3-11.8,11.8-11.8,11.8,5.3,11.8,11.8-5.3,11.8-11.8,11.8ZM78.2,47.7c-4.8,0-8.8,3.9-8.8,8.8s3.9,8.8,8.8,8.8,8.8-3.9,8.8-8.8-3.9-8.8-8.8-8.8Z"/>
                  </svg>
                  <div class="group-hover:visible invisible absolute -top-2.5 -left-24 p-2 bg-gray-200 max-w-[12rem] rounded-lg text-center shadow text-highlight text-sm">
                    {{'USERS.IMPERSONATE' | translate}}
                  </div>
              </div>           
            </div>
          </div>
        </div>



        <app-pagination [pages]="pageInfo"
                        (newPage)="router.navigate(['/users'], {queryParams:  {searchTerm: pageInfo.searchTerm, page: $event}}).then()"></app-pagination>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!users || users.length== 0">
    <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 m-4 text-sm" role="alert">
      <p>{{'GENERAL.EMPTY' | translate}}</p>
    </div>
  </ng-container>

  <ng-container *ngIf="showDeleteModal">
    <app-default-modal
      (toggleModal)="showDeleteModal = false"
      (returnObject$)="deleteUser((helperService.getObjectByName(selectedUser.fields, 'id')).value)"
      [textObject]="{header: 'USERS.DELETE_USER', text: 'USERS.DELETE_USER_TEXT' | translate: {'name': (helperService.getObjectByName(selectedUser.fields, 'username')).value}}">
    </app-default-modal>
  </ng-container>

  <ng-container *ngIf="changedUserArray.length >0 && showCustomToastMessage">
    <div class="realtive">
      <div
        class="absolute bottom-3 right-3 flex flex-row bg-gray-50 shadow-lg p-3 text-sm leading-none font-medium rounded-xl whitespace-no-wrap">
        <div class="flex flex-col">
          <div class="border-b p-2">
            {{'USERS.COUNT_CHANGED_USERS' | translate: {'count': changedUserArray.length} }}
          </div>
          <div class="p-3 flex space-x-4">
            <button type="button"
                    class="button-outline"
                    (click)="reloadPageParameters()">{{'GENERAL.CANCEL' | translate}}</button>
            <button type="button"
                    class="button-highlight"
                    (click)="updateUserList(); showCustomToastMessage = false">{{'GENERAL.SAVE' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
