import {Injectable} from '@angular/core';
import {HttpHandler, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable, of, Subject, throwError} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {catchError} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from './auth.service';
import {environment} from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';


@Injectable()
export class HttpInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(private cookieService: CookieService,
              private authService: AuthService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.authService.username$.next(this.cookieService.get('username'));

    if(environment.externalKeycloakUrl) {
      let url = new URL(environment.externalKeycloakUrl);
      if(request.url.includes(url.hostname)) {
        return next.handle(request)
      }
    }

    request = this.addAuthHeader(request);
    return next.handle(request).pipe(catchError(error => {
      return this.handleResponseError(error, request, next);
    }));
  }


  public addAuthHeader(request) {

    return request.clone({
      headers: request.headers
        .set("X-Requested-With", "XMLHttpRequest")
        .set("withCredentials", "true")
    })
  }


  public handleResponseError(error, request?, next?) {
    if (error.status === 401) {

      if(request.url !== `${environment.apiUrl}/token/login`) {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.INVALID_SESSION'));
        this.authService.logout()
        return EMPTY;
      }
    }

    return throwError(error);
  }

}
