import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {HelperService} from '../../services/helper.service';
import {IUserRoles} from "../../interfaces/user.roles";

@Component({
  selector: 'app-user-roles-overview',
  templateUrl: './user-roles-overview.component.html'
})
export class UserRolesOverviewComponent implements OnInit, OnDestroy {
  public currentId: string;
  public Subscriptions: Subscription[] = [];
  public userRoles: IUserRoles[];
  public username: string;


  constructor(public userService: UserService,
              public helperService: HelperService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.Subscriptions.push(this.route
      .params.subscribe(params => {
        this.currentId = params.id
        if (this.currentId) {
          this.userService.getUserRolesById(this.currentId);
        }
      }));
    this.Subscriptions.push(this.route
      .queryParams
      .subscribe(params => {
        this.username = params.username;
      }));

    this.Subscriptions.push(this.userService.userRoles$.subscribe(data => {
      this.userRoles = data;
    }));

  }

  ngOnDestroy() {
    if (this.Subscriptions) {
      this.Subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

}
