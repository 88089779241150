<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div (click)="importModalClose()"
         class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div (click)="$event.stopPropagation()"
        class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div
              class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-highlight sm:mx-0 sm:h-10 sm:w-10">
              <svg class="flex-shrink-0 w-6 h-6 text-white transition duration-75 group-hover:text-highlight"
                   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m0-3l-3-3m0 0l-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"/>
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title">{{'SIDEBAR.IMPORT' | translate}}</h3>
              <div class="mt-5">
                <label for="file" class="button-outline my-2" [innerText]="'GENERAL.UPLOAD_TXT' | translate"></label>
                <input id="file" type="file" style="display:none;" accept=".csv" class="form-control-file" (change)="changeListener($event.target.files)">
                <p class="text-gray-400 pl-1" *ngIf="selectedFileName">{{ selectedFileName }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button [disabled]="!importFile"
                  (click)="uploadImportFile(); importModalClose()"
                  type="button"
                  class="button-highlight mx-1">
            {{'SIDEBAR.TO_IMPORT' | translate}}</button>
          <button (click)="importModalClose()" type="button"
                  class="button-gray mx-1">
            {{'GENERAL.CANCEL' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
