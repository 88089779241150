import {Injectable} from '@angular/core';

declare let document: any;

@Injectable({
  providedIn: 'root'
})

export class ThemingService {

  constructor() {
  }

  public setTheme(themeName: string): void {
    document.documentElement.dataset['theme'] = themeName.toUpperCase();
  }

}
