import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {IObject, IPage} from '../interfaces/field.interface';
import {HelperService} from './helper.service';
import {IPasswordPolicy} from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class UserRestService {

  constructor(private http: HttpClient,
              private helperService: HelperService) {
  }
  public getUsers(searchTerm: string, page: number): Observable<IPage> {
    if (searchTerm) {
      return this.http.get<IPage>(
        `${environment.apiUrl}/user/${environment.realmConfig}?searchTerm=${searchTerm}&page=${page}`
      );
    } else {
      return this.http.get<IPage>(
        `${environment.apiUrl}/user/${environment.realmConfig}?page=${page}`
      );
    }
  }

  public getUserById(id: string): Observable<IObject> {
    return this.http.get<IObject>(
      `${environment.apiUrl}/user/${environment.realmConfig}/${id}`
    );
  }


  public getUserStructure(): Observable<IObject> {
    return this.http.get<IObject>(
      `${environment.apiUrl}/user/${environment.realmConfig}/structure`
    );
  }

  public getPasswordStructure(id:string): Observable<IObject> {
    return this.http.get<IObject>(
      `${environment.apiUrl}/user/${environment.realmConfig}/${id}/password/structure`
    );
  }

  public getPasswordPolicy(): Observable<IPasswordPolicy[]> {
    return this.http.get<IPasswordPolicy[]>(`${environment.apiUrl}/user/${environment.realmConfig}/password/policy`)
  }


  public getUserRolesById(id: string): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/user/${environment.realmConfig}/${id}/roles`
    );
  }

  public deleteUser(id: string) {
    return this.http.delete(
      `${environment.apiUrl}/user/${environment.realmConfig}/${id}`
    );
  }

  public updateUser(user: IObject) {
    return this.http.put<IObject>(
      `${environment.apiUrl}/user/${environment.realmConfig}/${this.helperService.getObjectByName(user.fields, 'id').value}`, user
    );
  }


  public updateUserActive( users: IObject[], searchTerm: string, page: number) {
    if (searchTerm) {
      return this.http.put<any>(
        `${environment.apiUrl}/user/${environment.realmConfig}/active?searchTerm=${searchTerm}&page=${page}`, users
      );
    } else {
      return this.http.put<any>(
        `${environment.apiUrl}/user/${environment.realmConfig}/active?page=${page}`, users
      );
    }
  }


  public setPassword(pw: IObject, userId: string) {
    return this.http.put<IObject>(
      `${environment.apiUrl}/user/${environment.realmConfig}/${userId}/password`, pw
    );
  }


  public createUser(user: IObject) {
    return this.http.post<IObject>(
      `${environment.apiUrl}/user/${environment.realmConfig}`, user
    );
  }

  public getAccessToken(): Observable<string> {
    return this.http.get(
      `${environment.apiUrl}/token/get-token`, {responseType: 'text'}
    );
  }

  public impersonate(userId: string, realm: string, token: string): Observable<any> {
    let headers = new HttpHeaders({
      'Authorization': token});
    let options = { headers: headers, withCredentials: true };
    return this.http.post<any>(
      `${environment.externalKeycloakUrl}/admin/realms/${realm}/users/${userId}/impersonation`, {
        user: userId, realm: realm}, options
    );
  }
}
