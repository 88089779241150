<ng-container *ngIf="(firmService.showErrorPageFirms$ | async) === 'ERROR_FIRMS'">
  <app-default-error-page></app-default-error-page>
</ng-container>

<ng-container *ngIf="(firmService.showErrorPageFirms$ | async) === 'LOADING'">
  <app-default-loading-page></app-default-loading-page>
</ng-container>

<ng-container *ngIf="(firmService.showErrorPageFirms$ | async) === 'CONTENT_FIRMS'">
  <div class="card-header-div">
    <h1 class="h1-header mr-4 flex-1">
      {{'FIRMS.HEADER' | translate}}
    </h1>
    <div class="flex justify-center flex-1">
      <app-search [type]="'firms'"
                  (onSearchEmitter)="router.navigate(['/firms'], {queryParams:  {searchTerm: $event, page: 0}}).then()"></app-search>
    </div>
    <div class="flex flex-1 justify-end gap-x-2">
      <button *ngIf="allowCreation" class="button-highlight" routerLink="new">{{'GENERAL.APPEND' | translate}}</button>
    </div>
  </div>

  <ng-container *ngIf="firms && firms.length >0">
    <div class="p-5">
      <div class="card-div">
        <div class="table-header-row">
          <div class="w-1/12 table-column">
            <h2> {{'GENERAL.LIST.ACTIVE' | translate}}</h2>
          </div>
          <div class="w-3/12 table-column">
            <h2 *ngIf="checkVisibility('NAME')">{{'GENERAL.LIST.NAME' | translate}}</h2>
          </div>
          <div class="w-4/12 table-column">
            <h2 *ngIf="checkVisibility('ADDRESS')">{{'GENERAL.LIST.ADDRESS' | translate}}</h2>
          </div>
          <div class="w-2/12 table-column">
            <h2 *ngIf="checkVisibility('HEADQUARTER')">{{'GENERAL.LIST.HEADQUADER' | translate}}</h2>
          </div>
          <div class="w-2/12 table-column justify-center">
          </div>
          <div class="w-[5px]"></div>
        </div>
        <div class="overflow-y-auto max-h-[calc(100vh-17.5rem)]">
          <div *ngFor="let firm of firms; let last = last"
               class="table-row cursor-pointer border-b"
               [ngClass]="{'rounded-b-md' : last}">
            <div class="w-1/12 table-column">
              <input type="checkbox" class="input-checkbox"
                     [checked]="helperService.getObjectByName(firm.fields, 'active').value"
                     [disabled]="true">
            </div>
            <div class="w-3/12 table-column"
                 routerLink="{{(helperService.getObjectByName(firm.fields, 'id')).value}}">
              <div *ngIf="checkVisibility('NAME')">
                 <span
                   *ngFor="let item of [].constructor(helperService.getObjectByName(firm.fields, 'indentation')?.value);">&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
                {{(helperService.getObjectByName(firm.fields, 'displayName')).value}}
              </div>
            </div>
            <div class="w-4/12 table-column"
                 routerLink="{{(helperService.getObjectByName(firm.fields, 'id')).value}}">
              <div *ngIf="checkVisibility('ADDRESS')">
                <span
                  *ngIf="helperService.getObjectByName(firm.fields, 'street').value">{{(helperService.getObjectByName(firm.fields, 'street')).value}}
                  ,</span>
                {{(helperService.getObjectByName(firm.fields, 'zip')).value}} {{(helperService.getObjectByName(firm.fields, 'city')).value}}
              </div>
            </div>
            <div class="w-2/12 table-column" routerLink="{{(helperService.getObjectByName(firm.fields, 'id')).value}}">
              <div *ngIf="checkVisibility('HEADQUARTER')">
                {{(helperService.getObjectByName(firm.fields, 'headOffice')).value}}
              </div>
            </div>
            <div class="w-2/12 table-column justify-end">
              <div *ngIf="helperService.getObjectByName(firm.fields, 'customSettings').value"
                   class="group hover:text-highlight hover:bg-gray-200 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg sm:mx-0 sm:h-10 sm:w-10 relative">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     data-tooltip-target="tooltip-bottom" data-tooltip-placement="bottom"
                     stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"/>
                </svg>
                <div
                  class="group-hover:visible invisible absolute mt-[-5.5rem] ml-[-5.5rem] pl-4 pr-4 pt-2 pb-2 bg-gray-100 max-w-[20rem] rounded-lg text-center text-highlight shadow text-sm">
                  {{'FIRMS.CUSTOM_SETTINGS' | translate}}
                </div>
              </div>
              <div
                class="hover:text-highlight hover:bg-gray-200 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg sm:mx-0 sm:h-10 sm:w-10">
                <svg routerLink="{{(helperService.getObjectByName(firm.fields, 'id')).value}}"
                     xmlns="http://www.w3.org/2000/svg"
                     class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"/>
                </svg>
              </div>
              <div *ngIf="allowDeletion"
                   class="hover:text-sso-red hover:bg-red-100 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg sm:mx-0 sm:h-10 sm:w-10">
                <svg (click)="selectedFirm = firm; showDeleteModal = true" xmlns="http://www.w3.org/2000/svg"
                     class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <app-pagination [pages]="pageInfo"
                        (newPage)="router.navigate(['/firms'], {queryParams:  {searchTerm: pageInfo.searchTerm, page: $event}}).then()"></app-pagination>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!firms || firms.length== 0">
    <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 m-4 text-sm" role="alert">
      <p>{{'GENERAL.EMPTY' | translate}}</p>
    </div>
  </ng-container>

  <ng-container *ngIf="showDeleteModal">
    <app-default-modal
      (toggleModal)="showDeleteModal = false"
      (returnObject$)="deleteFirm((helperService.getObjectByName(selectedFirm.fields, 'id')).value)"
      [textObject]="{header: 'FIRMS.DELETE_FIRM', text: 'FIRMS.DELETE_FIRM_TEXT' | translate: {'name': (helperService.getObjectByName(selectedFirm.fields, 'name')).value}}">
    </app-default-modal>
  </ng-container>
</ng-container>
