<div *ngIf="fullPage"  class="default-loading-page">
  <div class="preloader">
    <div class="main-loader"></div>
  </div>
</div>

<div *ngIf="!fullPage" class="default-loading-page">
  <div class="preloader small">
    <div class="main-loader small"></div>
  </div>
</div>
