import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-default-loading-page',
  templateUrl: './default-loading-page.component.html',
  styleUrls: ['./default-loading-page.component.scss']
})
export class DefaultLoadingPageComponent implements OnInit {
  @Input() fullPage?: boolean = true;

  constructor() {
  }

  ngOnInit(): void {
  }

}
