import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-default-modal',
  templateUrl: './default-modal.component.html'
})
export class DefaultModalComponent implements OnInit {
  public JSON = JSON;

  @Input() textObject = {
    header: '',
    text: '',
  };
  @Input() btnName?: string = '';
  @Input() jsonObject?: string = '';
  @Input() hideButton?: boolean = false;
  @Output() returnObject$ = new EventEmitter<boolean>();
  @Output() toggleModal = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

  public modalClose(): void {
    this.toggleModal.emit(false);
  }

  public emitObject(): void {
    this.returnObject$.emit();
  }
}
