import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit {
  @Input() pages = {
    totalElements: 0,
    totalPages: 0,
    currentPage: 0,
    results: 0,
    firstPage: false,
    lastPage: false
  };

  @Output() newPage: EventEmitter<number> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }


  public goToPage(page: number): void {
    this.pages.currentPage = page;
    this.newPage.emit(page);
  }

  public previousPage(): void {
    if (this.pages.currentPage == 0) {
      return
    }
    this.pages.currentPage -= 1;
    this.newPage.emit(this.pages.currentPage);
  }

  public nextPage(): void {
    if (this.pages.currentPage + 1 == this.pages.totalPages) {
      return
    }
    this.pages.currentPage += 1;
    this.newPage.emit(this.pages.currentPage);
  }

}
