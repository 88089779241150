import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './main/main.component';
import {LoginComponent} from './login/login.component';
import {RootComponent} from './root/root.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {HttpInterceptor} from './auth/http.interceptor';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {CommonModule, registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';
import {FormsModule} from '@angular/forms';
import {CookieService} from 'ngx-cookie-service';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GroupOverviewComponent} from './groups/group-overview/group-overview.component';
import {DefaultErrorPageComponent} from './shared/default-error-page/default-error-page.component';
import {DefaultLoadingPageComponent} from './shared/default-loading-page/default-loading-page.component';
import {GroupEditComponent} from './groups/group-edit/group-edit.component';
import {ClientOverviewComponent} from './clients/client-overview/client-overview.component';
import {ClientEditComponent} from './clients/client-edit/client-edit.component';
import {FirmOverviewComponent} from './firms/firm-overview/firm-overview.component';
import {FirmEditComponent} from './firms/firm-edit/firm-edit.component';
import {UserOverviewComponent} from './users/user-overview/user-overview.component';
import {UserEditComponent} from './users/user-edit/user-edit.component';
import {FilterPipe} from './pipes/filter.pipe';
import {CompareListsComponent} from './shared/compare-lists/compare-lists.component';
import {UserRolesComponent} from './users/user-roles/user-roles.component';
import {PaginationComponent} from './shared/pagination/pagination.component';
import {SearchComponent} from './shared/search/search.component';
import {PrefixFocusDirective} from './directives/prefix-focus.directive';
import {EventsOverviewComponent} from './events/events-overview/events-overview.component';
import {UserRolesOverviewComponent} from './users/user-roles-overview/user-roles-overview.component';
import {ImportLogOverviewComponent} from './import-log/import-log-overview/import-log-overview.component';
import {ImportModalComponent} from './root/import-modal/import-modal.component';
import {ExportModalComponent} from './root/export-modal/export-modal.component';
import {DefaultModalComponent} from './shared/default-modal/default-modal.component';
import {RelocationModalComponent} from './firms/relocation-modal/relocation-modal.component';
import {EnvironmentLoaderService} from './services/environment-loader.service';

registerLocaleData(localeDe);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function EnvironmentLoaderFactory(envService: EnvironmentLoaderService) {
  return () => envService.loadEnvConfig('/assets/config/app-config.json');
};

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    RootComponent,
    GroupOverviewComponent,
    DefaultErrorPageComponent,
    DefaultLoadingPageComponent,
    GroupEditComponent,
    RootComponent,
    ClientOverviewComponent,
    ClientEditComponent,
    UserOverviewComponent,
    UserEditComponent,
    FilterPipe,
    CompareListsComponent,
    UserRolesComponent,
    ClientEditComponent,
    FirmOverviewComponent,
    FirmEditComponent,
    PaginationComponent,
    SearchComponent,
    PrefixFocusDirective,
    UserRolesOverviewComponent,
    EventsOverviewComponent,
    ImportLogOverviewComponent,
    ImportModalComponent,
    ExportModalComponent,
    DefaultModalComponent,
    RelocationModalComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      newestOnTop: false,
      progressBar: true,
      progressAnimation: 'increasing',
      tapToDismiss: true
    }),
    TranslateModule.forRoot({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory
      }
    }),
    FormsModule
  ],
  providers: [
    CookieService,
    {provide: LOCALE_ID, useValue: 'de'},
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true},
    HttpClientModule,
    HttpClient,
    EnvironmentLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: EnvironmentLoaderFactory,
      multi: true,
      deps: [EnvironmentLoaderService],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
