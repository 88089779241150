<div class="mt-2">
  <ng-container *ngIf="pages.totalPages <= 10">
    <div class="bg-white px-4 py-3 flex justify-center relative sm:px-6">
      <div>
        <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <!-- Previous Button -->
          <a (click)="previousPage()"
             class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer">
            <span class="sr-only">Previous</span>
            <!-- Heroicon name: solid/chevron-left -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"/>
            </svg>
          </a>

          <a *ngFor="let page of [].constructor(pages.totalPages); let pageNumber = index"
             [ngClass]="{'text-highlight border-highlight z-10 bg-gray-50': pages.currentPage == pageNumber}"
             (click)="goToPage(pageNumber)"
             class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm cursor-pointer"
          >
            {{pageNumber + 1}}
          </a>

          <!-- Next Button -->
          <a (click)="nextPage()"
             class="relative inline-flex items-center rounded-r-md px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer">
            <span class="sr-only">Next</span>
            <!-- Heroicon name: solid/chevron-right -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"/>
            </svg>
          </a>
        </nav>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="pages.totalPages > 10">
    <div class="bg-white px-4 py-3 flex justify-center relative sm:px-6">
      <div>
        <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <!-- Previous Button -->
          <a (click)="previousPage()"
             class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer">
            <span class="sr-only">Previous</span>
            <!-- Heroicon name: solid/chevron-left -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"/>
            </svg>
          </a>

          <!-- First Page -->
          <a [ngClass]="{'text-highlight border-highlight z-10 bg-gray-50': pages.currentPage ==  0}"
             (click)="goToPage(0)"
             class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm cursor-pointer"
          >
            1
          </a>
          <!-- Beginning Section -->
          <ng-container *ngIf="pages.currentPage < 3">
            <a *ngFor="let page of [].constructor(3); let pageNumber = index"
               [ngClass]="{'text-highlight border-highlight z-10 bg-gray-50': pages.currentPage == pageNumber+1}"
               (click)="goToPage(pageNumber+1)"
               class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm cursor-pointer"
            >
              {{pageNumber + 2}}
            </a>
          </ng-container>

          <span
            class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
					...
				</span>

          <!-- Middle Section  -->
          <ng-container *ngIf="pages.currentPage >= 3 && pages.currentPage < pages.totalPages-3">
            <a *ngFor="let page of [].constructor(3); let pageNumber = index"
               [ngClass]="{'text-highlight border-highlight z-10 bg-gray-50': pages.currentPage+1 == pageNumber+pages.currentPage}"
               (click)="goToPage(pageNumber+pages.currentPage-1)"
               class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm cursor-pointer"
            >
              {{pageNumber + pages.currentPage}}
            </a>
            <span
              class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
						...
					</span>
          </ng-container>

          <!-- Last Section -->
          <ng-container *ngIf="pages.currentPage >= pages.totalPages-3">
            <a *ngFor="let page of [].constructor(3); let pageNumber = index"
               [ngClass]="{'text-highlight border-highlight z-10 bg-gray-50': pages.currentPage == pageNumber+pages.totalPages-4}"
               (click)="goToPage(pageNumber+pages.totalPages-4)"
               class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm cursor-pointer"
            >
              {{pageNumber + pages.totalPages - 3}}
            </a>
          </ng-container>


          <!-- Last Page -->
          <a [ngClass]="{'text-highlight border-highlight z-10 bg-gray-50': pages.currentPage ==  pages.totalPages-1}"
             (click)="goToPage(pages.totalPages-1)"
             class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm cursor-pointer"
          >
            {{pages.totalPages}}
          </a>

          <!-- Next Button -->
          <a (click)="nextPage()"
             class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white rounded-r-md text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer">
            <span class="sr-only">Next</span>
            <!-- Heroicon name: solid/chevron-right -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"/>
            </svg>
          </a>
        </nav>
      </div>
    </div>
  </ng-container>
</div>
