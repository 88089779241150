import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  public username: string;
  public password: string;
  public realmName = environment.realmConfig;
  public realmNames: string[] = environment.realmNames;
  public showRealm = environment.showRealmName;
  public showRealmSelection = false;
  public loading: boolean = false;
  public masterLogin = false;

  constructor(private authService: AuthService,
              private router: Router) {
  }

  ngOnInit(): void {
    if(this.router.url.endsWith("master")) {
      this.realmNames = ["master"]
      this.realmName = "master"
      this.showRealm = false
    }


    if (this.realmNames.length > 0) {
      this.showRealmSelection = true;
    }

    // autonaviagte if user is already loggedIn
    if (this.authService.isAuthenticated) {
      this.router.navigate(['/']).then();
    }
  }

  public changeRealmInEnv(event: any): void {
    this.realmName = event.target.value
    environment.realmConfig = this.realmName;
  }

  async onSubmit(event: Event, valid: boolean) {
    this.loading = true;
    event.preventDefault();
    if (valid) {
      this.authService.login(this.username, this.password, this.realmName).then(() => this.loading = false)
    }
  }

}
