import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit {
  public showValidation: boolean = false;
  public st: string;
  @Input() type: string;
  @Output() private onSearchEmitter: EventEmitter<any> = new EventEmitter();

  constructor(public helperService: HelperService,
              public cookieService: CookieService) {
  }

  ngOnInit(): void {
    this.st = this.cookieService.get('searchterm_' + this.type)
  }


  public keyUpFunction(event: KeyboardEvent, search: string): void {
    if (search.length < 3 && search.length > 0) {
      this.showValidation = true;
    }
    if (search.length >= 3) {
      this.showValidation = false;
    }
    if (search.length < 1) {
      this.showValidation = false;
    }

    if (event.keyCode === 13) {
      this.onSearch(search);
    }
  }

  public onSearch(search: string): void {
    if (search.length >= 3) {
      this.onSearchEmitter.emit(this.st);
    } else if (search.length < 1) {
      this.cookieService.set('searchterm_' + this.type, '');
      this.onSearchEmitter.emit('');
    }
  }

  public changeCookieSearchTerm(value: any): void {
    this.cookieService.set('searchterm_' + this.type, value.target.value);
  }

}
