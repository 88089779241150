import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {IPage} from "../interfaces/field.interface";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ImportLogRestService {

  constructor(private http: HttpClient,) {
  }

  public getImportLogs(type: string, page: number, f?: string, t?: string): Observable<IPage> {

    if (type == 'all') {
      if (f && t) {
        return this.http.get<IPage>(
          `${environment.apiUrl}/user-administration/${environment.realmConfig}/import/logs/all?page=${page}&from=${f}&to=${t}`
        );
      } else if (f && !t) {
        return this.http.get<IPage>(
          `${environment.apiUrl}/user-administration/${environment.realmConfig}/import/logs/all?page=${page}&from=${f}`
        );
      } else {
        return this.http.get<IPage>(
          `${environment.apiUrl}/user-administration/${environment.realmConfig}/import/logs/all?page=${page}`
        );
      }
    }
    if (type == 'error') {
      if (f && t) {
        return this.http.get<IPage>(
          `${environment.apiUrl}/user-administration/${environment.realmConfig}/import/logs/error?page=${page}&from=${f}&to=${t}`
        );
      }
      if (f && !t) {
        return this.http.get<IPage>(
          `${environment.apiUrl}/user-administration/${environment.realmConfig}/import/logs/error?page=${page}&from=${f}`
        );
      }else {
        return this.http.get<IPage>(
          `${environment.apiUrl}/user-administration/${environment.realmConfig}/import/logs/error?page=${page}`
        );
      }
    }
  }
}
