import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {IObject, IPage} from '../interfaces/field.interface';
import {HelperService} from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class FirmRestService {

  constructor(private http: HttpClient,
              private helperService: HelperService) {
  }

  public getFirms(searchTerm: string, page: number): Observable<IPage> {
    if (searchTerm) {
      return this.http.get<IPage>(
        `${environment.apiUrl}/firm/${environment.realmConfig}?searchTerm=${searchTerm}&page=${page}`
      );
    } else {
      return this.http.get<IPage>(
        `${environment.apiUrl}/firm/${environment.realmConfig}?page=${page}`
      );
    }
  }


  public getFirmList(): Observable<IObject[]> {
      return this.http.get<any>(
        `${environment.apiUrl}/firm/${environment.realmConfig}/list`
      );
  }

  public getFirmById(id: string): Observable<IObject> {
    return this.http.get<IObject>(
      `${environment.apiUrl}/firm/${environment.realmConfig}/${id}`
    );
  }

  public getFirmStructure(): Observable<IObject> {
    return this.http.get<IObject>(
      `${environment.apiUrl}/firm/${environment.realmConfig}/structure`
    );
  }

  public deleteFirm(id: string) {
    return this.http.delete(
      `${environment.apiUrl}/firm/${environment.realmConfig}/${id}`
    );
  }

  public resetToParentFirm(firm: IObject) {
    return this.http.put<IObject>(
      `${environment.apiUrl}/firm/${environment.realmConfig}/${this.helperService.getObjectByName(firm.fields, 'id').value}/reset-to-parent`, firm
    );
  }

  public relocateFirm(firmId: number,newParentFirmId: number ) {
    return this.http.put<any>(
      `${environment.apiUrl}/firm/${environment.realmConfig}/${firmId}/relocation?newParent=${newParentFirmId}`, null
    );
  }


  public updateFirm(firm: IObject) {
    return this.http.put<IObject>(
      `${environment.apiUrl}/firm/${environment.realmConfig}/${this.helperService.getObjectByName(firm.fields, 'id').value}`, firm
    );
  }


  public createFirm(firm: IObject) {
    return this.http.post<IObject>(
      `${environment.apiUrl}/firm/${environment.realmConfig}`, firm
    );
  }

  public createSubfirm(firm: IObject, parentId: string): Observable<IObject> {
    return this.http.post<IObject>(
      `${environment.apiUrl}/firm/${environment.realmConfig}/${parentId}`, firm
    );
  }


}
