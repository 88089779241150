import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IPage} from '../interfaces/field.interface';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EventRestService {

  constructor(private http: HttpClient,) {
  }

  public getEvents(searchTerm: string, page: number, f?: string, t?: string): Observable<IPage> {
    if (searchTerm) {
      if (f && t) {
        return this.http.get<IPage>(
          `${environment.apiUrl}/events/${environment.realmConfig}?searchTerm=${searchTerm}&page=${page}&from=${f}&to=${t}`
        );
      } else if (f && !t) {
        return this.http.get<IPage>(
          `${environment.apiUrl}/events/${environment.realmConfig}?searchTerm=${searchTerm}&page=${page}&from=${f}`
        );
      } else {
        return this.http.get<IPage>(
          `${environment.apiUrl}/events/${environment.realmConfig}?searchTerm=${searchTerm}&page=${page}`
        );
      }
    } else {
      if (f && t) {
        return this.http.get<IPage>(
          `${environment.apiUrl}/events/${environment.realmConfig}?page=${page}&from=${f}&to=${t}`
        );
      } else if (f && !t) {
        return this.http.get<IPage>(
          `${environment.apiUrl}/events/${environment.realmConfig}?page=${page}&from=${f}`
        );
      } else {
        return this.http.get<IPage>(
          `${environment.apiUrl}/events/${environment.realmConfig}?page=${page}`
        );
      }

    }
  }
}
