<div class="relative ">
  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
    <svg class="w-6 h-6 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0
						01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd">
      </path>
    </svg>
  </div>
  <input #search type="text" id="search" placeholder="{{ 'GENERAL.PLACEHOLDER_3_CHAR' | translate}}"
         [(ngModel)]="st"
         (change)="changeCookieSearchTerm($event)"
         (keyup)="keyUpFunction($event, search.value)"
         class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-highlight focus:border-highlight block w-80 pl-10 p-2.5 outline-none">
  <div class="absolute inset-y-0 right-1 flex items-center pl-3 cursor-pointer">
    <svg *ngIf="st.length >0" (click)="onSearch('')"
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
         class="w-6 h-6 text-sso-red">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
    </svg>

  </div>
  <div *ngIf="showValidation" class="absolute text-sso-red pl-1 top-10">
    {{'MESSAGES.ERROR.VALIDATION_MIN_LENGTH' | translate}}
  </div>
</div>
