import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ClientService} from '../../services/client.service';
import {ClientRestService} from '../../services/client-rest.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {IObject} from '../../interfaces/field.interface';
import {HelperService} from '../../services/helper.service';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-client-overview',
  templateUrl: './client-overview.component.html'
})
export class ClientOverviewComponent implements OnInit {
  public Subscriptions: Subscription[] = [];
  public clients: IObject[];
  public selectedClient = {} as IObject;
  public showNoResults: boolean = false;
  public showDeleteModal = false;
  public allowDeletion: boolean = false;
  public allowCreation: boolean = false;

  constructor(public clientService: ClientService,
              public clientRestService: ClientRestService,
              public helperService: HelperService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.Subscriptions.push(this.authService.outline$.subscribe(res => {
      if (res) {
        this.allowDeletion = this.helperService.findObjectByName(res, 'CLIENTS')?.allowDeletion;
        this.allowCreation = this.helperService.findObjectByName(res, 'CLIENTS')?.allowCreation;
      }
    }));
    this.clientService.getClients();
    this.Subscriptions.push(this.clientService.clients$.subscribe(data => {
      if (data) {
        this.clients = data;
        this.showNoResults = false;
      }
      if (data && data.length === 0) {
        this.showNoResults = true;
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.Subscriptions) {
      this.Subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }


  public deleteClient(id: string): void {
    this.clientRestService.deleteClient(id).subscribe(
      {
        next: () => {
          const index = this.clients.findIndex(client => this.helperService.getObjectByName(client.fields, 'idOfClient').value === id);
          if (index >= 0) {
            this.clients.splice(index, 1);
          }
          this.toastr.success(this.translate.instant('MESSAGES.SUCCESS.DATA'));
        },
        error: () => {
          this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA_SAVE'));
        }
      }
    )
  }
}
