<ng-container *ngIf="(importLogService.showErrorPageImportLog$ | async) === 'ERROR_IMPORT_LOGS'">
  <app-default-error-page></app-default-error-page>
</ng-container>

<ng-container *ngIf="(importLogService.showErrorPageImportLog$ | async) === 'LOADING'">
  <app-default-loading-page></app-default-loading-page>
</ng-container>

<ng-container *ngIf="(importLogService.showErrorPageImportLog$ | async) === 'CONTENT_IMPORT_LOGS'">
  <div class="card-header-div">
    <h1 class="h1-header mr-4 flex-1">
      {{'IMPORT_LOG.HEADER' | translate}}
    </h1>
    <div class="flex flex-1 justify-end gap-x-2">
      <form #timeForm="ngForm">
        <div class="grid grid-cols-2 gap-x-2">
          <div class="relative">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6 text-highlight absolute inset-y-2 left-1">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"/>
            </svg>
            <input class="input-field w-full !my-0 !pl-7" type="date" id="start" name="start" #start
                   data-date-format="DD.MM.YYYY" min="2018-01-01"
                   [(ngModel)]="fromTime"/>
          </div>
          <div class="relative">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6 text-highlight absolute inset-y-2 left-1">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"/>
            </svg>
            <input class="input-field w-full !my-0 !pl-7" type="date" id="end" name="end" #end
                   data-date-format="DD.MM.YYYY" min="2018-01-01"
                   [disabled]="!fromTime" [(ngModel)]="toTime"/>
          </div>
        </div>
      </form>
      <div class="flex items-center">
        <input (change)="changeOnlyError($event.target.checked)" type="checkbox" [(ngModel)]="onlyError"
               class="input-checkbox" id="only-error">
        <label class="label-checkbox"
               for="only-error">{{'IMPORT_LOG.ONLY_ERROR' | translate}}</label>
      </div>
      <button class="button-outline" (click)="clear()"
              type="button">{{'GENERAL.CLEAR' | translate}}</button>
      <button class="button-highlight" (click)="pageInfo.currentPage = 0; logsByDate(undefined, undefined)"
              type="button">{{'GENERAL.TO_UPDATE' | translate}}</button>
    </div>
  </div>

  <ng-container *ngIf="logs && logs.length >0">
    <div class="p-5">
      <div class="card-div">
        <div class="table-header-row">
          <div class="w-2/12 table-column">
            <h2>{{'IMPORT_LOG.IMPORT_TIME' | translate}}</h2>
          </div>
          <div class="w-2/12 table-column">
            <h2>{{'IMPORT_LOG.STATUS' | translate}}</h2>
          </div>
          <div class="w-4/12 table-column">
            <h2>{{'IMPORT_LOG.MESSAGE' | translate}}</h2>
          </div>
          <div class="w-2/12 table-column">
            <h2>{{'IMPORT_LOG.EXECUTING_USER' | translate}}</h2>
          </div>
          <div class="w-2/12 table-column justify-center">
          </div>
          <div class="w-[5px]"></div>
        </div>
        <div class="overflow-y-auto max-h-[calc(100vh-17.5rem)]">
          <ng-container *ngFor="let log of logs; let last = last; let index = index">
            <div
              (click)="helperService.getObjectByName(log.fields, 'logs').value && helperService.getObjectByName(log.fields, 'logs').value.length? showLogInfo[index] = !showLogInfo[index] : ''"
              class="table-row cursor-pointer border-b"
              [ngClass]="{'rounded-b-md' : last, '!bg-gray-100':showLogInfo[index]}">
              <div class="w-2/12 table-column">
                {{(helperService.getObjectByName(log.fields, 'importStart')).value | date : 'medium'}}
              </div>
              <div class="w-2/12 table-column">
                {{helperService.getObjectByName(log.fields, 'status').value}}
              </div>
              <div class="w-4/12 table-column">
                {{(helperService.getObjectByName(log.fields, 'message')).value}}
              </div>
              <div class="w-2/12 table-column">
                {{(helperService.getObjectByName(log.fields, 'executingUser')).value}}
              </div>
              <div class="w-2/12 table-column justify-end">
                <div class="h-12 w-12 sm:h-10 sm:w-10">
                  <div
                    (click)="$event.stopPropagation(); showLogInfo[index]= !showLogInfo[index]"
                    [ngClass]="{'text-highlight bg-gray-200': showLogInfo[index]}"
                    *ngIf="helperService.getObjectByName(log.fields, 'logs').value && helperService.getObjectByName(log.fields, 'logs').value.length"
                    class="hover:text-highlight hover:bg-gray-200 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                            d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="helperService.getObjectByName(log.fields, 'logs').value && helperService.getObjectByName(log.fields, 'logs').value.length"
              [ngClass]="showLogInfo[index]? 'w-full border-b border-x px-6 pb-10': 'hidden'">
              <div *ngFor="let entry of  (helperService.getObjectByName(log.fields, 'logs')).value; let l = last"
                   class="grid gap-2 grid-cols-3 pt-2"
                   [ngClass]="{'border-b' : !l}">
                <span *ngFor="let item of entry | keyvalue">
                  <span *ngIf="item.key && item.value">
                    {{item.key}}: <b>{{item.value}}</b>
                  </span>
                </span>

              </div>
            </div>
          </ng-container>
        </div>
        <app-pagination [pages]="pageInfo"
                        (newPage)="logsByDate($event, undefined)"></app-pagination>
      </div>
    </div>
  </ng-container>


  <ng-container *ngIf="!logs || logs.length== 0">
    <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 m-4 text-sm" role="alert">
      <p>{{'GENERAL.EMPTY' | translate}}</p>
    </div>
  </ng-container>
</ng-container>
