<div class="flex p-2">
  <div [ngClass]="readOnly? 'w-6/12': 'w-5/12'">
    <div *ngIf="leftTitle" class="text-wrapper my-2">{{leftTitle}}</div>
    <div>
      <input type="text" name="search-left" class="input-field w-full !mb-0 !rounded-b-none"
             [placeholder]="'GENERAL.SEARCH' | translate"
             [(ngModel)]="search.left">
      <div class="border-x border-b rounded-b overflow-y-auto max-h-[10rem]">
        <ng-container *ngIf="(leftList | json) == '[]'">
          <div class="text-slate-400 text-center w-full min-h-[2.5rem] text-sm">{{'GENERAL.EMPTY' | translate}}</div>
        </ng-container>
        <ng-container *ngFor="let left of leftList | appFilter: search.left; let last = last">
          <ng-container *ngIf="left">
            <div
              class="cursor-pointer p-2 flex justify-between"
              (click)="changeSelectedItem ($event, 'left', left)"
              [ngClass]="{'bg-gray-100': selectedLeft && selectedLeft === left, 'border-b': !last}">
              <div class="text-gray-700 text-sm">
                <span>{{left.displayName ? left.displayName : left.name ? left.name : left.clientName}}</span>
                <span class="text-slate-400" *ngIf="showPseudoRealm"> ({{left.pseudoRealmKey}})</span>
                <span *ngIf="showRequiredGroups && left?.mandatoryUniqueKey" class="font-semibold ml-2"> * </span>
                <span *ngIf="(showRequiredGroups > 1) && left?.mandatoryUniqueKey"
                      class="ml-3">({{'GENERAL.CLUSTER' | translate}}:  {{left?.mandatoryUniqueKey}})</span>
              </div>
              <div class="flex gap-x-1">
                <svg *ngIf="link" [routerLink]="[link+(left.id ? left.id : left.idOfClient)]"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="w-6 h-6 hover:text-highlight">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"/>
                </svg>
                <svg *ngIf="showDetails"
                     (click)="showInfoBox = true; groupName=(left.name ? left.name : left.clientName); groupDescription = left.description"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="w-6 h-6 hover:text-highlight">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"/>
                </svg>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div [ngClass]="readOnly? 'hidden': 'w-1/12 grid justify-items-center content-center'">
    <div (click)="( restriction==='right1' || !selectedRight)? '': moveItem('toLeft')"
         [ngClass]="{'cursor-not-allowed pointer !text-gray-300':( restriction==='right1' || !selectedRight)}"
         class="cursor-pointer text-highlight bg-gray-200 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
           aria-hidden="true">
        <path fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"/>
      </svg>
    </div>
  </div>
  <div [ngClass]="readOnly? 'hidden': 'w-1/12 grid justify-items-center content-center'">
    <div (click)="(restriction==='left1' || !selectedLeft)? '' : moveItem('toRight')"
         [ngClass]="{'cursor-not-allowed !text-gray-300': (restriction==='left1' || !selectedLeft)}"
         class="cursor-pointer text-highlight bg-gray-200 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
           aria-hidden="true">
        <path fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"/>
      </svg>
    </div>
  </div>
  <div [ngClass]="readOnly? 'w-6/12': 'w-5/12'">
    <div *ngIf="rightTitle" class="text-wrapper my-2">{{rightTitle}}</div>
    <div>
      <input type="text" name="search-right" class="input-field w-full !mb-0 !rounded-b-none"
             [placeholder]="'GENERAL.SEARCH' | translate"
             [(ngModel)]="search.right">
      <div class="border-x border-b rounded-b overflow-y-auto max-h-[10rem]">
        <ng-container *ngIf="(rightList | json) == '[]'">
          <div class="text-slate-400 text-center w-full min-h-[2.5rem] text-sm">{{'GENERAL.EMPTY' | translate}}</div>
        </ng-container>
        <ng-container *ngFor="let right of rightList | appFilter: search.right; let last = last">
          <ng-container *ngIf="right">
            <div
              class="cursor-pointer p-2 flex justify-between"
              (click)="changeSelectedItem ($event, 'right', right)"
              [ngClass]="{'bg-gray-100': selectedRight && selectedRight === right, 'border-b': !last}">
              <div class="text-gray-700 text-sm">
                <span>{{right.displayName ? right.displayName : right.name ? right.name : right.clientName}}</span>
                <span class="text-slate-400" *ngIf="showPseudoRealm"> ({{right.pseudoRealmKey}})</span>
                <span *ngIf="showRequiredGroups && right?.mandatoryUniqueKey" class="font-semibold ml-2"> * </span>
                <span *ngIf="(showRequiredGroups > 1) && right?.mandatoryUniqueKey"
                      class="ml-3">({{'GENERAL.CLUSTER' | translate}}:  {{right?.mandatoryUniqueKey}})</span>
              </div>
              <div class="flex gap-x-1">
                <svg *ngIf="link" [routerLink]="[link+(right.id ? right.id : right.idOfClient)]"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="w-6 h-6 hover:text-highlight">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"/>
                </svg>
                <svg *ngIf="showDetails"
                     (click)="showInfoBox = true; groupName=(right.name ? right.name : right.clientName); groupDescription = right.description"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="w-6 h-6 hover:text-highlight">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"/>
                </svg>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>


<div *ngIf="showInfoBox" class="bg-slate-100 border-l-4 border-slate-700 text-slate-700 p-4 m-4 relative"
     role="alert">
  <p class="font-semibold mb-2">
    {{groupName}}
  </p>
  <p>
    {{groupDescription}}
  </p>
  <span class="absolute top-0 bottom-0 right-0 px-4 py-3"
        (click)="showInfoBox = false; groupName=''; groupDescription= ''">
    <svg class="fill-current h-6 w-6 text-highlight" role="button" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 20 20"><title>Close</title><path
      d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
  </span>
</div>
