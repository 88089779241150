import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {IObject, IPage} from '../interfaces/field.interface';
import {HelperService} from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class GroupRestService {

  constructor(private http: HttpClient,
              private helperService: HelperService) { }

  public getGroups(searchTerm: string, page: number): Observable<IPage> {
    if (searchTerm) {
      return this.http.get<IPage>(
        `${environment.apiUrl}/group/${environment.realmConfig}?searchTerm=${searchTerm}&page=${page}`
      );
    } else {
      return this.http.get<IPage>(
        `${environment.apiUrl}/group/${environment.realmConfig}?page=${page}`
      );
    }
  }

  public getGroupById(id:string): Observable<IObject> {
    return this.http.get<IObject>(
      `${environment.apiUrl}/group/${environment.realmConfig}/${id}`
    );
  }

  public getGroupStructure(): Observable<IObject> {
    return this.http.get<IObject>(
      `${environment.apiUrl}/group/${environment.realmConfig}/structure`
    );
  }

  public getPseudoRealm(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/firm/${environment.realmConfig}/pseudo-realms`
    );
  }

  public deleteGroup(id: string) {
    return this.http.delete(
      `${environment.apiUrl}/group/${environment.realmConfig}/${id}`
    );
  }



  public updateGroup(group: IObject) {
    return this.http.put<IObject>(
      `${environment.apiUrl}/group/${environment.realmConfig}/${this.helperService.getObjectByName(group.fields, 'id').value}`, group
    );
  }

  public createGroup(group: IObject) {
    return this.http.post<IObject>(
      `${environment.apiUrl}/group/${environment.realmConfig}`, group
    );
  }

}
