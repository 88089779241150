import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './main/main.component';
import {LoginComponent} from './login/login.component';
import {RootComponent} from './root/root.component';
import {AuthGuard} from './auth/auth.guard';
import {ClientOverviewComponent} from './clients/client-overview/client-overview.component';
import {ClientEditComponent} from './clients/client-edit/client-edit.component';
import {GroupOverviewComponent} from './groups/group-overview/group-overview.component';
import {GroupEditComponent} from './groups/group-edit/group-edit.component';
import {FirmOverviewComponent} from './firms/firm-overview/firm-overview.component';
import {FirmEditComponent} from './firms/firm-edit/firm-edit.component';
import {UserOverviewComponent} from './users/user-overview/user-overview.component';
import {UserEditComponent} from './users/user-edit/user-edit.component';
import {UserRolesOverviewComponent} from './users/user-roles-overview/user-roles-overview.component';
import {EventsOverviewComponent} from './events/events-overview/events-overview.component';
import {ImportLogOverviewComponent} from "./import-log/import-log-overview/import-log-overview.component";

const routes: Routes = [{
  path: '',
  data: {state: 'main'},
  component: MainComponent,
  children: [
    {
      path: 'login',
      component: LoginComponent
    },
    {
      path: 'login/master',
      component: LoginComponent
    },
    {
      path: '',
      data: {state: 'root'},
      component: RootComponent,
      canActivate: [AuthGuard],
      children: [
        {
          path: '',
          redirectTo: 'users',
          pathMatch: 'full'
        },
        {
          path: 'groups',
          children: [
            {
              path: '',
              component: GroupOverviewComponent,
            },
            {
              path: 'new',
              component: GroupEditComponent
            },
            {
              path: ':id',
              component: GroupEditComponent
            }
          ]
        },
        {
          path: 'events',
          component: EventsOverviewComponent,
        },
        {
          path: 'import-log',
          component: ImportLogOverviewComponent,
        },
        {
          path: 'users',
          children: [
            {
              path: '',
              component: UserOverviewComponent,
            },
            {
              path: 'new',
              component: UserEditComponent
            },
            {
              path: 'roles/:id',
              component: UserRolesOverviewComponent
            },
            {
              path: ':id',
              component: UserEditComponent
            }
          ]
        },
        {
          path: 'clients',
          children: [
            {
              path: '',
              component: ClientOverviewComponent,
            },
            {
              path: 'new',
              component: ClientEditComponent
            },
            {
              path: ':id',
              component: ClientEditComponent
            }
          ]
        },
        {
          path: 'firms',
          children: [
            {
              path: '',
              component: FirmOverviewComponent,
            },
            {
              path: 'new',
              component: FirmEditComponent
            },
            {
              path: 'new-subfirm/:id',
              component: FirmEditComponent
            },
            {
              path: ':id',
              component: FirmEditComponent
            }
          ]
        }
      ]
    }]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
