import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ClientService} from '../../services/client.service';
import {ClientRestService} from '../../services/client-rest.service';
import {ActivatedRoute} from '@angular/router';
import {HelperService} from '../../services/helper.service';
import {IObject} from '../../interfaces/field.interface';
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html'
})
export class ClientEditComponent implements OnInit, OnDestroy {
  public currentId: string;
  public Subscriptions: Subscription[] = [];
  public client = {} as IObject;
  public redirectTocuhed_WORK_ARROUND: boolean;
  public loading: boolean = false;

  constructor(private route: ActivatedRoute,
              public helperService: HelperService,
              public clientService: ClientService,
              private clientRestService: ClientRestService,
              private translate: TranslateService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.startUp().then();
  }

  ngOnDestroy(): void {
    if (this.Subscriptions) {
      this.Subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }


  async startUp() {
    await this.createOrUpdateCheck().finally(() => {
        this.Subscriptions.push(this.clientService.client$.subscribe(data => {
          if (data) {
            this.client = data;
          }
        }))
      }
    )
  }

  public createOrUpdateCheck(): Promise<boolean> {
    return new Promise((resolve) => {
      this.route.params.subscribe(params => {
        this.currentId = params.id
        if (this.currentId) {
          this.clientService.getClientById(this.currentId);
          resolve(true);
        } else {
          this.clientService.getClientStructure();
          resolve(false);
        }
      })
    });
  }


  public addListItem(item: string, type: string): void {
    switch (type) {
      case"roles":
        item.length > 0 && (this.helperService.getObjectByName(this.client.fields, 'roles').value ?
          this.helperService.getObjectByName(this.client.fields, 'roles').value.push(item) :
          (this.helperService.getObjectByName(this.client.fields, 'roles').value = [], this.helperService.getObjectByName(this.client.fields, 'roles').value.push(item)));
        break;
      case"redirectUris":
        item.length > 0 && (this.helperService.getObjectByName(this.client.fields, 'redirectUris').value ?
          this.helperService.getObjectByName(this.client.fields, 'redirectUris').value.push(item) :
          (this.helperService.getObjectByName(this.client.fields, 'redirectUris').value = [], this.helperService.getObjectByName(this.client.fields, 'redirectUris').value.push(item)));
        break;
      case"groups":
        item.length > 0 && (this.helperService.getObjectByName(this.client.fields, 'groups').value ?
          this.helperService.getObjectByName(this.client.fields, 'groups').value.push(item) :
          (this.helperService.getObjectByName(this.client.fields, 'groups').value = [], this.helperService.getObjectByName(this.client.fields, 'groups').value.push(item)));
        break;
    }
  }

  public removeListItem(item: string, type: string): void {
    switch (type) {
      case"roles":
        let idx1 = this.helperService.getObjectByName(this.client.fields, 'roles').value.findIndex(e => e === item);
        if (idx1 > 0) {
          this.helperService.getObjectByName(this.client.fields, 'roles').value.splice(idx1, 1);
        }
        if (
          idx1 == 0) {
          this.helperService.getObjectByName(this.client.fields, 'roles').value.shift()
        }
        break;
      case"redirectUris":
        let idx2 = (this.helperService.getObjectByName(this.client.fields, 'redirectUris').value.findIndex(e => e === item));
        if (idx2 > 0) {
          this.helperService.getObjectByName(this.client.fields, 'redirectUris').value.splice(idx2, 1);
        }
        if (
          idx2 == 0) {
          this.helperService.getObjectByName(this.client.fields, 'redirectUris').value.shift()
        }
        break;
      case"groups":
        let idx3 = this.helperService.getObjectByName(this.client.fields, 'groups').value.findIndex(e => e === item);
        if (idx3 > 0) {
          this.helperService.getObjectByName(this.client.fields, 'groups').value.splice(idx3, 1);
        }
        if (
          idx3 == 0) {
          this.helperService.getObjectByName(this.client.fields, 'groups').value.shift()
        }
        break;
    }
  }


  public keyDownFunction(event: KeyboardEvent, valid: boolean): void {
    if (valid && event.keyCode === 13) {
      this.submitData();
    }
  }

  public onSubmit(event: Event, valid: boolean): void {
    event.preventDefault();
    if (valid) {
      this.submitData();
    }
  }

  public submitData(): void {
    this.loading = true;
    let request;
    if (this.currentId) {
      request = this.clientRestService.updateClient(this.client as IObject);
    } else {
      request = this.clientRestService.createClient(this.client as IObject);
    }
    request.pipe(finalize(() => this.loading = false)).subscribe({
      next: (res) => {
        this.client = res;
        this.currentId = this.helperService.getObjectByName(res.fields, 'idOfClient').value;
        this.toastr.success(this.translate.instant('MESSAGES.SUCCESS.DATA'));
      },
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA_SAVE'));
      }
    })
  }

}
