import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {GroupRestService} from './group-rest.service';
import {IObject, IPage} from '../interfaces/field.interface';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  public showErrorPageGroups$: BehaviorSubject<string> = new BehaviorSubject<string>('LOADING');
  public groups$: BehaviorSubject<IPage> = new BehaviorSubject<IPage>(null);
  public group$: Subject<IObject> = new Subject<IObject>();

  constructor(
    private groupRestService: GroupRestService,
    private translate: TranslateService,
    private toastr: ToastrService) {
  }

  public getGroups(searchTerm: string, page: number): void {
    this.showErrorPageGroups$.next('LOADING');
    this.groupRestService.getGroups(searchTerm, page).subscribe({
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA'));
        this.showErrorPageGroups$.next('ERROR_GROUPS');
      },
      next: (response) => {
        response ? this.groups$.next(response) : this.groups$.next(null);
        this.showErrorPageGroups$.next('CONTENT_GROUPS');
      }
    })
  }

  public getGroupById(id: string): void {
    this.showErrorPageGroups$.next('LOADING');
    this.groupRestService.getGroupById(id).subscribe({
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA'));
        this.showErrorPageGroups$.next('ERROR_GROUP');
      },
      next: (response) => {
        response ? this.group$.next(response) : this.group$.next(null);
        this.showErrorPageGroups$.next('CONTENT_GROUP');
      }
    })
  }

  public getGroupStructure(): void {
    this.showErrorPageGroups$.next('LOADING');
    this.groupRestService.getGroupStructure().subscribe({
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA'));
        this.showErrorPageGroups$.next('ERROR_GROUP');
      },
      next: (response) => {
        response ? this.group$.next(response) : this.group$.next(null);
        this.showErrorPageGroups$.next('CONTENT_GROUP');
      }
    })
  }

}
