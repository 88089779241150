import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {GroupService} from '../../services/group.service';
import {GroupRestService} from '../../services/group-rest.service';
import {Location} from '@angular/common';
import {HelperService} from '../../services/helper.service';
import {IObject} from '../../interfaces/field.interface';
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html'
})
export class GroupEditComponent implements OnInit, OnDestroy {
  public currentId: string;
  public Subscriptions: Subscription[] = [];
  public group = {} as IObject;
  public pseudoRealms: IObject[];
  public loading: boolean = false;

  constructor(private route: ActivatedRoute,
              public helperService: HelperService,
              public groupService: GroupService,
              private groupRestService: GroupRestService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private location: Location) {
  }

  ngOnInit(): void {
    this.startUp().then();
  }

  ngOnDestroy(): void {
    if (this.Subscriptions) {
      this.Subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

  async startUp() {
    await this.createOrUpdateCheck().then(res => {
      this.Subscriptions.push(this.groupService.group$.subscribe(data => {
        if (data) {
          this.group = data;
          if (!res) {
            this.helperService.getObjectByName(this.group.fields, 'firmId').value = null;
          }
        }
      }))
    })
  }


  public createOrUpdateCheck(): Promise<boolean> {
    return new Promise((resolve) => {
      this.route.params.subscribe(params => {
        this.currentId = params.id
        if (this.currentId) {
          this.groupService.getGroupById(this.currentId);
          resolve(true);
        } else {
          this.groupService.getGroupStructure();
          this.groupRestService.getPseudoRealm().subscribe(
            {
              next: (res) => {
                this.pseudoRealms = res;
              },
              error: () => {
                this.toastr.error(this.translate.instant('MESSAGES.ERROR.GET_DEPENDENT_RESOURCES'));
              }
            }
          );
          resolve(false);
        }
      })
    });
  }


  public keyDownFunction(event: KeyboardEvent, valid: boolean): void {
    if (valid && event.keyCode === 13) {
      this.submitData();
    }
  }

  public onSubmit(event: Event, valid: boolean): void {
    event.preventDefault();
    if (valid) {
      this.submitData();
    }
  }

  public submitData(): void {
    this.loading = true;
    let request;
    if (this.currentId) {
      request = this.groupRestService.updateGroup(this.group as IObject);
    } else {
      this.helperService.getObjectByName(this.group.fields, 'firmId').value = parseInt(this.helperService.getObjectByName(this.group.fields, 'firmId').value);
      request = this.groupRestService.createGroup(this.group as IObject);
    }
    request.pipe(finalize(() => this.loading = false)).subscribe(
      {
        next: (response) => {
          if (response) {
            this.group = response;
            this.currentId = this.helperService.getObjectByName(response.fields, 'id').value;
            this.location.replaceState('/groups/' + this.helperService.getObjectByName(response.fields, 'id').value);
          }
          this.toastr.success(this.translate.instant('MESSAGES.SUCCESS.DATA'));
        },
        error: (error) => {
          if (error.error.status == 409 && error.error.message == 'group with this name already exists') {
            this.toastr.error(this.translate.instant('MESSAGES.ERROR.GROUP_EXISTS'));
          }
          this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA_SAVE'));
        }
      }
    );
  }

}
