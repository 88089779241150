import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isAuthenticated().pipe(
      catchError(() => of(false)), // do not let the user access the route if authentication can neither be confirmed nor denied
      tap(isAuthenticated => {
        if (!isAuthenticated && !this.router.url.includes("login")) {
          this.router.navigate(['/login']); // redirect user to login if they're not authenticated
        }
      })
    );
  }

}
