import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-compare-lists',
  templateUrl: './compare-lists.component.html'
})
export class CompareListsComponent implements OnInit {
  @Input() public checkDuplicatetPseudoRealm?: boolean | false;
  @Input() public showDetails?: boolean | false;
  @Input() public restriction?: string;
  @Input() public leftTitle?: string;
  @Input() public rightTitle?: string;
  @Input() public leftList?: any;
  @Input() public rightList?: any;
  @Input() public readOnly ?: boolean | false;
  @Input() public link?: string;
  @Input() public showRequiredGroups?: number;
  @Output() private OnUpdatetLists: EventEmitter<any> = new EventEmitter();
  public showInfoBox: boolean;
  public groupName: string = '';
  public groupDescription: string = '';

  public showPseudoRealm;
  public selectedLeft;
  public selectedRight;
  public search = {
    left: '',
    right: ''
  };

  constructor() {
  }

  ngOnInit(): void {
    if (this.checkDuplicatetPseudoRealm) {
      const tempArr = this.leftList.concat(this.rightList);
      this.showPseudoRealm = this.conArray(tempArr).length > 1;
    }
  }


  public conArray(arr: []) {
    const res = {};
    arr.forEach((obj) => {
      const key = `${obj["pseudoRealmKey"]}`;
      if (!res[key]) {
        res[key] = {obj, count: 0};
      }
      res[key].count += 1;
    });
    return Object.values(res);
  }


  public moveItem(way: string): void {
    if (this.selectedRight || this.selectedLeft) {
      let updatedSide: string;
      let updatedItem: any;
      switch (way) {
        case 'toLeft':
          if (this.selectedRight) {
            updatedItem = this.selectedRight
            if (this.restriction === 'left1') {
              this.leftList[0] ? this.rightList.push(this.leftList[0]) : {};
              const t = this.rightList.findIndex(t => t === this.selectedRight);
              t >= 0 && (this.rightList.splice(t, 1), (this.leftList = [], this.leftList.push(this.selectedRight)))
            }
            const t = this.rightList.findIndex(t => t === this.selectedRight);
            t >= 0 && (this.rightList.splice(t, 1), this.leftList ? this.leftList.push(this.selectedRight) : (this.leftList = [], this.leftList.push(this.selectedRight)))
            this.selectedRight = null;
          }
          updatedSide = 'right';
          break;
        case 'toRight':
          if (this.selectedLeft) {
            updatedItem = this.selectedLeft
            if (this.restriction === 'right1') {
              this.rightList[0] ? this.leftList.push(this.rightList[0]) : {};
              const t = this.leftList.findIndex(t => t === this.selectedLeft);
              t >= 0 && (this.leftList.splice(t, 1), (this.rightList = [], this.rightList.push(this.selectedLeft)))
              this.selectedLeft = null;
            }
            const t = this.leftList.findIndex(t => t === this.selectedLeft);
            t >= 0 && (this.leftList.splice(t, 1), this.rightList ? this.rightList.push(this.selectedLeft) : (this.rightList = [], this.rightList.push(this.selectedLeft)))
            this.selectedLeft = null;
          }
          updatedSide = 'left';
          break;
      }
      this.OnUpdatetLists.emit({
        leftList: this.leftList,
        rightList: this.rightList,
        updatedSide: updatedSide,
        updatedItem: updatedItem
      });
    }
  }


  public changeSelectedItem(event: any, side: string, item: any): void {
    if (event.target.nodeName === 'DIV') {
      this.showInfoBox = false;
      this.groupName = '';
      this.groupDescription = ''
    }

    switch (side) {
      case 'left':
        this.selectedLeft = item;
        break;
      case 'right':
        this.selectedRight = item;
        break;
    }
  }
}
