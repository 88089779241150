import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {FirmRestService} from './firm-rest.service';
import {IObject, IPage} from '../interfaces/field.interface';

@Injectable({
  providedIn: 'root'
})
export class FirmService {
  public showErrorPageFirms$: BehaviorSubject<string> = new BehaviorSubject<string>('LOADING');
  public firms$: BehaviorSubject<IPage> = new BehaviorSubject<IPage>(null);
  public firm$: Subject<IObject> = new Subject<IObject>();

  constructor(private firmRestService: FirmRestService,
              private translate: TranslateService,
              private toastr: ToastrService
  ) {
  }


  public getFirms(searchTerm: string, page: number): void {
    this.showErrorPageFirms$.next('LOADING');
    this.firmRestService.getFirms(searchTerm, page).subscribe({
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA'));
        this.showErrorPageFirms$.next('ERROR_FIRMS');
      },
      next: (response) => {
        response ? this.firms$.next(response) : this.firms$.next(null);
        this.showErrorPageFirms$.next('CONTENT_FIRMS');
      }
    })
  }

  public getFirmById(id: string): void {
    this.showErrorPageFirms$.next('LOADING');
    this.firmRestService.getFirmById(id).subscribe({
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA'));
        this.showErrorPageFirms$.next('ERROR_FIRM');
      },
      next: (response) => {
        response ? this.firm$.next(response) : this.firm$.next(null);
        this.showErrorPageFirms$.next('CONTENT_FIRM');
      }
    })
  }

  public getFirmStructure(): void {
    this.showErrorPageFirms$.next('LOADING');
    this.firmRestService.getFirmStructure().subscribe({
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA'));
        this.showErrorPageFirms$.next('ERROR_FIRM');
      },
      next: (response) => {
        response ? this.firm$.next(response) : this.firm$.next(null);
        this.showErrorPageFirms$.next('CONTENT_FIRM');
      }
    })
  }

}
