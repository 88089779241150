import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {FirmService} from '../../services/firm.service';
import {FirmRestService} from '../../services/firm-rest.service';
import {IObject} from '../../interfaces/field.interface';
import {HelperService} from '../../services/helper.service';
import {AuthService} from '../../auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';


@Component({
  selector: 'app-firm-overview',
  templateUrl: './firm-overview.component.html'
})

export class FirmOverviewComponent implements OnInit, OnDestroy {
  public Subscriptions: Subscription[] = [];
  public firms: IObject[];
  public selectedFirm = {} as IObject;
  public showNoResults: boolean = false;
  public showDeleteModal = false;
  public allowDeletion: boolean = false;
  public allowCreation: boolean = false;
  public overViewColumns: string[];
  public pageInfo: any = {
    currentPage: 0,
    searchTerm: '',
    totalPages: undefined,
    firstPage: undefined,
    lastPage: undefined,
  };


  constructor(public firmService: FirmService,
              public helperService: HelperService,
              public firmRestService: FirmRestService,
              private translate: TranslateService,
              public route: ActivatedRoute,
              public router: Router,
              private cookieService: CookieService,
              private toastr: ToastrService,
              public authService: AuthService
  ) {
  }


  ngOnInit(): void {

    this.Subscriptions.push(this.authService.outline$.subscribe(res => {
      if (res) {
        this.allowDeletion = this.helperService.findObjectByName(res, 'FIRMS')?.allowDeletion || this.helperService.findObjectByName(res, 'SUB_FIRM')?.allowDeletion;
        this.allowCreation = this.helperService.findObjectByName(res, 'FIRMS')?.allowCreation || this.helperService.findObjectByName(res, 'SUB_FIRM')?.allowCreation;
        this.overViewColumns = this.helperService.findObjectByName(res, 'FIRMS')?.overviewColumns;
      }
    }));

    this.Subscriptions.push(this.route
      .queryParams
      .subscribe(params => {
        if (Object.keys(params).length !== 0) {
          this.pageInfo.currentPage = params.page ? parseInt(params.page) : 0;
          this.pageInfo.searchTerm = params.searchTerm ? params.searchTerm : '';
          this.cookieService.set('searchterm_firms', this.pageInfo.searchTerm);
          this.firmService.getFirms(this.pageInfo.searchTerm, this.pageInfo.currentPage);
        } else {
          this.cookieService.set('searchterm_firms', '');
          this.cookieService.set('show_firms', 'false');
          this.firmService.getFirms('', 0);
        }

      }));

    this.Subscriptions.push(this.firmService.firms$.subscribe(data => {
      if (data) {
        this.pageInfo.firstPage = data.first;
        this.pageInfo.lastPage = data.last;
        this.pageInfo.totalPages = data.totalPages;
        this.pageInfo.totalElements = data.totalElements;
        this.firms = data.content;
        this.showNoResults = false;
      }
      if (data && data.empty) {
        this.showNoResults = true;
      }
    }));
  }


  ngOnDestroy(): void {
    if (this.Subscriptions) {
      this.Subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

  public checkVisibility(input: string): boolean {
    return this.overViewColumns.some(x => x === input);
  }


  public deleteFirm(id: string): void {
    this.firmRestService.deleteFirm(id).subscribe(
      {
        next: () => {
          const index = this.firms.findIndex(firm => this.helperService.getObjectByName(firm.fields, 'id').value === id);
          if (index >= 0) {
            this.firms.splice(index, 1);
          }
          this.toastr.success(this.translate.instant('MESSAGES.SUCCESS.DATA'));
        },
        error: (error) => {
          if (error.status == 422) {
            if (error.error.message == 'trying to delete firm with subfirms') {
              this.toastr.error(this.translate.instant('MESSAGES.ERROR.DELETE_MAINFIRM'));
            } else if (error.error.message == 'trying to delete firm with users') {
              this.toastr.error(this.translate.instant('MESSAGES.ERROR.DELETE_FIRM_WITH_USERS'));
            } else {
              this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA_SAVE'));
            }
          } else {
            this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA_SAVE'));
          }
        }
      }
    )
  }


}
