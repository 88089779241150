import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HelperService} from "../../services/helper.service";

@Component({
  selector: 'app-relocation-modal',
  templateUrl: './relocation-modal.component.html'
})
export class RelocationModalComponent implements OnInit {

  @Input() textObject = {
    header: '',
    text: '',
  };
  @Input() firms;
  @Output() returnObject$ = new EventEmitter<number>();
  @Output() toggleModal = new EventEmitter<boolean>();

  public selectedFirm: string;

  constructor(public helperService: HelperService) {
  }

  ngOnInit(): void {
  }

  public modalClose(): void {
    this.toggleModal.emit(false);
  }

  public emitObject(): void {
    const id = this.selectedFirm.substring(this.selectedFirm.indexOf('/') + 2);
    this.returnObject$.emit(parseInt(id));
    this.toggleModal.emit(false);
  }
}
