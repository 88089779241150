<ng-container *ngIf="(firmService.showErrorPageFirms$ | async) === 'ERROR_FIRM'">
  <app-default-error-page></app-default-error-page>
</ng-container>

<ng-container *ngIf="(firmService.showErrorPageFirms$ | async) === 'LOADING'">
  <app-default-loading-page></app-default-loading-page>
</ng-container>

<ng-container *ngIf="(firmService.showErrorPageFirms$ | async) === 'CONTENT_FIRM'">
  <div class="card-header-div">
    <h1 class="h1-header mr-4 flex-1 flex items-center">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="w-6 h-6 cursor-pointer mr-1" [routerLink]="['../']">
        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
      </svg>
      <span>
        {{'FIRMS.HEADER' | translate}} &nbsp;</span>
      <span *ngIf="currentId">- {{helperService.getObjectByName(firm.fields, 'name').value}}</span>
      <span *ngIf="!currentId">- {{'FIRMS.NEW_FIRM' | translate}}</span>
    </h1>
    <div class="flex flex-1 justify-end gap-x-2">
      <button *ngIf="currentId && allowCreation  && !isNewSubfirm" class="button-highlight"
              routerLink="/firms/new-subfirm/{{helperService.getObjectByName(firm.fields, 'id').value}}">
        {{'FIRMS.CREATE_SUBFIRM' | translate}}</button>
      <button *ngIf="allowRelocation && currentId && !isNewSubfirm" class="button-highlight" type="button"
              (click)="showReloactionModal= true">{{'FIRMS.RELOCATION' | translate}}</button>
      <button *ngIf="helperService.getObjectByName(firm.fields, 'customSettings')?.value" type="button"
              (click)="showDefaultModal = true"
              class="button-highlight"> {{'FIRMS.STANDARD_SETTING'  | translate}}</button>
      <button class="button-highlight" type="submit"
              (click)="onSubmit($event, !firmForm.invalid)"
              [disabled]="firmForm.invalid">
          <span *ngIf="loading" role="status">
              <svg aria-hidden="true" class="mr-2 w-4 h-4 animate-spin fill-highlight" viewBox="0 0 100 101" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"/>
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"/>
              </svg>
              <span class="sr-only">Loading...</span>
            </span>
        {{'GENERAL.SAVE' | translate}}
      </button>
    </div>
  </div>
  <div class="p-5">
    <div class="flex flex-col lg:flex-row">
      <div class="w-full max-lg:mb-4 lg:mr-4 lg:w-1/3 ">
        <div class="card-div overflow-y-auto max-h-[calc(100vh-7.5rem)]">
          <ul class="flex flex-wrap mb-8 text-sm font-medium text-center inline-flex" id="myTab" role="tablist">
            <!-- Data TAB -->
            <li class="mr-2" role="presentation">
              <button (click)="changeSelectedType('DATA')"
                      class="inline-block p-4 rounded-t-lg border-b-2 hover:border-highlight text-slate-500"
                      [ngClass]="{'border-highlight': selectedType === 'DATA', 'border-transparent': selectedType !== 'DATA'}"
                      type="button" role="tab">
                <span class="h2-header">{{'GENERAL.DATA' | translate}}</span>
              </button>
            </li>
            <!-- Zusatzinfos TAB -->
            <li class="mr-2" role="presentation" *ngIf="currentId && !isNewSubfirm">
              <button (click)="changeSelectedType('ATTRIBUTES')"
                      class="inline-block p-4 rounded-t-lg border-b-2 hover:border-highlight text-slate-500"
                      [ngClass]="{'border-highlight': selectedType === 'ATTRIBUTES', 'border-transparent': selectedType !== 'ATTRIBUTES'}"
                      type="button" role="tab">
                <span class="h2-header">{{'USERS.ATTRIBUTES' | translate}}</span>
              </button>
            </li>
          </ul>
          <form #firmForm="ngForm">
            <ng-container *ngIf="selectedType === 'DATA'">
              <div [ngClass]="helperService.getObjectByName(firm.fields, 'active').visible? 'shown': 'hidden'"
                   class="flex items-center">
                <input [checked]="helperService.getObjectByName(firm.fields, 'active').value"
                       (change)="helperService.getObjectByName(firm.fields, 'active').value = !helperService.getObjectByName(firm.fields, 'active').value"
                       [disabled]="helperService.getObjectByName(firm.fields, 'active').readOnly"
                       class="input-checkbox" id="active" type="checkbox">
                <label class="label-checkbox" for="active">{{'FIRMS.ACTIVE' | translate}}</label>
              </div>
              <div [ngClass]="helperService.getObjectByName(firm.fields, 'headquarter').visible? 'shown': 'hidden'"
                   class="flex items-center">
                <input [checked]="helperService.getObjectByName(firm.fields, 'headquarter').value"
                       (change)="helperService.getObjectByName(firm.fields, 'headquarter').value = !helperService.getObjectByName(firm.fields, 'headquarter').value"
                       [disabled]="helperService.getObjectByName(firm.fields, 'headquarter').readOnly"
                       class="input-checkbox" id="headquarter" type="checkbox">
                <label class="label-checkbox" for="headquarter">{{'FIRMS.HEADQUARTER' | translate}}</label>
              </div>
              <div [ngClass]="helperService.getObjectByName(firm.fields, 'name').visible? 'shown': 'hidden'">
                <label for="name" class="label">
                  {{'FIRMS.NAME' | translate}}*
                </label>
                <input class="input-field w-full" id="name"
                       type="text" [(ngModel)]="helperService.getObjectByName(firm.fields, 'name').value"
                       name="name" #name="ngModel" required
                       [disabled]="helperService.getObjectByName(firm.fields, 'name').readOnly"
                       (keydown)="keyDownFunction($event, firmForm.valid)"
                       [ngClass]="{'input-field-has-Error': name.invalid && name.touched}"
                />
                <span class="label-error-hint" *ngIf="name.invalid && name.touched">
              {{'INPUT_FIELD_ERROR.REQUIRED' | translate}}
            </span>
              </div>
              <div [ngClass]="helperService.getObjectByName(firm.fields, 'name').visible? 'shown': 'hidden'">
                <label for="description" class="label">
                  {{'FIRMS.DESCRIPTION' | translate}}
                </label>
                <input class="input-field w-full" id="description"
                       type="text" [(ngModel)]="helperService.getObjectByName(firm.fields, 'description').value"
                       name="description" #description="ngModel"
                       [disabled]="helperService.getObjectByName(firm.fields, 'description').readOnly"
                       (keydown)="keyDownFunction($event, firmForm.valid)"
                       [ngClass]="{'input-field-has-Error': description.invalid && description.touched}"
                />
              </div>
              <div *ngIf="helperService.getObjectByName(firm.fields, 'parentFirm')?.value"
                   [ngClass]="helperService.getObjectByName(firm.fields, 'parentFirm').visible? 'shown': 'hidden'">
                <label for="parentFirm" class="label">
                  {{'FIRMS.PARENT_FIRM' | translate}}
                </label>
                <input class="input-field w-full" id="parentFirm"
                       type="text" [(ngModel)]="helperService.getObjectByName(firm.fields, 'parentFirm').value"
                       name="parentFirm" #parentFirm="ngModel"
                       [disabled]="helperService.getObjectByName(firm.fields, 'parentFirm').readOnly"
                       (keydown)="keyDownFunction($event, firmForm.valid)"
                       [ngClass]="{'input-field-has-Error': parentFirm.invalid && parentFirm.touched}"
                />
              </div>
              <div [ngClass]="helperService.getObjectByName(firm.fields, 'pseudoRealmKey').visible? 'shown': 'hidden'">
                <label for="pseudoRealmKey" class="label">
                  {{'FIRMS.PSEUDO_REALM' | translate}}
                </label>
                <input class="input-field w-full" id="pseudoRealmKey"
                       type="text" [(ngModel)]="helperService.getObjectByName(firm.fields, 'pseudoRealmKey').value"
                       name="pseudoRealmKey" #pseudoRealmKey="ngModel"
                       [disabled]="helperService.getObjectByName(firm.fields, 'pseudoRealmKey').readOnly"
                       (keydown)="keyDownFunction($event, firmForm.valid)"
                       [ngClass]="{'input-field-has-Error': pseudoRealmKey.invalid && pseudoRealmKey.touched}"
                />
              </div>
              <div [ngClass]="helperService.getObjectByName(firm.fields, 'debitorNo').visible? 'shown': 'hidden'">
                <label for="debitorNo" class="label">
                  {{'debitorNo' | translate}}*
                </label>
                <input class="input-field w-full" id="debitorNo"
                       type="text" [(ngModel)]="helperService.getObjectByName(firm.fields, 'debitorNo').value"
                       name="debitorNo" #debitorNo="ngModel" required
                       [disabled]="helperService.getObjectByName(firm.fields, 'debitorNo').readOnly"
                       (keydown)="keyDownFunction($event, firmForm.valid)"
                       [ngClass]="{'input-field-has-Error': debitorNo.invalid && debitorNo.touched}"
                />
                <span class="label-error-hint" *ngIf="debitorNo.invalid && debitorNo.touched">
              {{'INPUT_FIELD_ERROR.REQUIRED' | translate}}
            </span>
              </div>
              <div [ngClass]="helperService.getObjectByName(firm.fields, 'street').visible? 'shown': 'hidden'">
                <label for="street" class="label">
                  {{'street' | translate}}
                </label>
                <input class="input-field w-full" id="street"
                       type="text" [(ngModel)]="helperService.getObjectByName(firm.fields, 'street').value"
                       name="street" #street="ngModel"
                       [disabled]="helperService.getObjectByName(firm.fields, 'street').readOnly"
                       (keydown)="keyDownFunction($event, firmForm.valid)"
                       [ngClass]="{'input-field-has-Error': street.invalid && street.touched}"
                />
              </div>
              <div [ngClass]="helperService.getObjectByName(firm.fields, 'zip').visible? 'shown': 'hidden'">
                <label for="zip" class="label">
                  {{'zip' | translate}}
                </label>
                <input class="input-field w-full" id="zip"
                       type="text" [(ngModel)]="helperService.getObjectByName(firm.fields, 'zip').value"
                       name="zip" #zip="ngModel"
                       [disabled]="helperService.getObjectByName(firm.fields, 'zip').readOnly"
                       (keydown)="keyDownFunction($event, firmForm.valid)"
                       [ngClass]="{'input-field-has-Error': zip.invalid && zip.touched}"
                />
              </div>
              <div [ngClass]="helperService.getObjectByName(firm.fields, 'city').visible? 'shown': 'hidden'">
                <label for="city" class="label">
                  {{'city' | translate}}
                </label>
                <input class="input-field w-full" id="city"
                       type="text" [(ngModel)]="helperService.getObjectByName(firm.fields, 'city').value"
                       name="city" #city="ngModel"
                       [disabled]="helperService.getObjectByName(firm.fields, 'city').readOnly"
                       (keydown)="keyDownFunction($event, firmForm.valid)"
                       [ngClass]="{'input-field-has-Error': city.invalid && city.touched}"
                />
              </div>
            </ng-container>
            <ng-container *ngIf="selectedType === 'ATTRIBUTES'">
              <ng-container
                *ngFor="let attribute of helperService.getObjectByName(firm.fields, 'attributes').value; let i = index;">
                <div [ngClass]="helperService.getObjectByName(firm.fields, 'attributes').visible? 'shown': 'hidden'">
                  <label for="{{attribute.name}}" class="label">
                    {{attribute.name}}
                  </label>
                  <input class="input-field w-full" id="{{attribute.name}}"
                         type="text"
                         (keydown)="keyDownFunction($event, firmForm.valid, 'DATA')"
                         name="{{attribute.name}}"
                         #attr="ngModel" [disabled]="attribute.readOnly"
                         [(ngModel)]="attribute.value"/>
                </div>
              </ng-container>
            </ng-container>
          </form>
        </div>
      </div>
      <div class="w-full max-lg:mb-4 lg:mr-4 lg:w-2/3 "
           *ngIf="(helperService.getObjectByName(firm.fields, 'groupMapping')?.value ||
              helperService.getObjectByName(firm.fields, 'clientMapping')?.value ||
              helperService.getObjectByName(firm.fields, 'groupSelectablesMapping')?.value  ||
              helperService.getObjectByName(firm.fields, 'clientSelectablesMapping')?.value ||
              helperService.getObjectByName(firm.fields, 'defaultGroupMapping')?.value ||
              currentId) && !isNewSubfirm">
        <div class="card-div overflow-y-auto max-h-[calc(100vh-7.5rem)]">
          <div id="accordion-flush" data-accordion="open">
            <div *ngIf="helperService.getObjectByName(firm.fields, 'clientSelectablesMapping')?.value"
                 [ngClass]="helperService.getObjectByName(firm.fields, 'clientSelectablesMapping').visible?  'shown': 'hidden'"
                 class="mb-5">
              <!-- auswählbare Anwendungen -->
              <div id="accordion-flush-heading-1">
                <button type="button"
                        (click)="clientSelectablesMappingShown =!clientSelectablesMappingShown"
                        class="flex items-center justify-between w-full py-5 text-left text-gray-700 border-b border-gray-200 text-sm"
                        data-accordion-target="#accordion-flush-body-1" aria-expanded="true"
                        aria-controls="accordion-flush-body-1">
                  <div class="flex items-center">
                    <span class="h2-header">{{'FIRMS.SELECTABLE_CLIENTS' | translate }}</span>
                    <span class="group ml-2">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                           stroke="currentColor" class="w-6 h-6 hover:text-highlight">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"/>
                      </svg>
                      <div
                        class="group-hover:visible invisible absolute mt-[-5.5rem] ml-[-5.5rem] pl-4 pr-4 pt-2 pb-2 bg-gray-100 max-w-[20rem] rounded-lg text-center text-highlight shadow">
                     {{'FIRMS.INFO_SELECTABLE_CLIENTS' | translate}}
                      </div>
                    </span>
                  </div>
                  <svg [ngClass]="{ 'rotate-180' : clientSelectablesMappingShown}"
                       class="w-6 h-6 shrink-0 transition-all duration-500" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"></path>
                  </svg>
                </button>
              </div>
              <div id="accordion-flush-body-1"
                   class="transition-all duration-500"
                   [ngClass]="clientSelectablesMappingShown? 'opacity-100 max-h-80' : 'opacity-0 max-h-0 overflow-hidden'"
                   aria-labelledby="accordion-flush-heading-1">
                <app-compare-lists
                  [readOnly]="helperService.getObjectByName(firm.fields, 'clientSelectablesMapping').readOnly"
                  [leftList]="helperService.getObjectByName(firm.fields, 'clientSelectablesMapping').value.active? helperService.getObjectByName(firm.fields, 'clientSelectablesMapping').value.active :  []"
                  [rightList]="helperService.getObjectByName(firm.fields, 'clientSelectablesMapping').value.inactive? helperService.getObjectByName(firm.fields, 'clientSelectablesMapping').value.inactive :  []"
                  [leftTitle]="translate.instant('FIRMS.ASSIGNED_CLIENTS')"
                  [rightTitle]="translate.instant('FIRMS.POSSIBLE_CLIENTS')"
                  [link]="'../../clients/'"
                  (OnUpdatetLists)="changeClientMapping(firm, $event )"></app-compare-lists>
              </div>
            </div>
            <div *ngIf="helperService.getObjectByName(firm.fields, 'clientMapping')?.value"
                 [ngClass]="helperService.getObjectByName(firm.fields, 'clientMapping').visible? 'shown': 'hidden'"
                 class="mb-5">
              <!-- Anwendungen -->
              <div id="accordion-flush-heading-2">
                <button type="button"
                        (click)="clientMappingShown =!clientMappingShown"
                        class="flex items-center justify-between w-full py-5 text-left text-gray-700 border-b border-gray-200 text-sm"
                        data-accordion-target="#accordion-flush-body-2" aria-expanded="true"
                        aria-controls="accordion-flush-body-2">
                  <div class="flex items-center">
                    <span class="h2-header">{{'FIRMS.CLIENTS' | translate }}</span>
                    <span *ngIf="helperService.getObjectByName(firm.fields, 'clientMapping')?.value.customSettings"
                          class="text-wrapper ml-2"> ({{'FIRMS.DIFFERENT_SETTINGS' | translate}})</span>
                  </div>
                  <svg [ngClass]="{ 'rotate-180' : clientMappingShown}"
                       class="w-6 h-6 shrink-0 transition-all duration-500" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"></path>
                  </svg>
                </button>
              </div>
              <div id="accordion-flush-body-2"
                   class="transition-all duration-500"
                   [ngClass]="clientMappingShown?  'opacity-100 max-h-80' : 'opacity-0 max-h-0 overflow-hidden'"
                   aria-labelledby="accordion-flush-heading-2">
                <app-compare-lists
                  [readOnly]="helperService.getObjectByName(firm.fields, 'clientMapping').readOnly"
                  [leftList]="helperService.getObjectByName(firm.fields, 'clientMapping').value.active? helperService.getObjectByName(firm.fields, 'clientMapping').value.active :  []"
                  [rightList]="helperService.getObjectByName(firm.fields, 'clientMapping').value.inactive? helperService.getObjectByName(firm.fields, 'clientMapping').value.inactive :  []"
                  [leftTitle]="translate.instant('FIRMS.ASSIGNED_CLIENTS')"
                  [rightTitle]="translate.instant('FIRMS.POSSIBLE_CLIENTS')"
                  [link]="'../../clients/'"
                  (OnUpdatetLists)="helperService.getObjectByName(firm.fields, 'clientMapping').value.active = $event.leftList; helperService.getObjectByName(firm.fields, 'clientMapping').value.inactive = $event.rightList;"></app-compare-lists>
              </div>
            </div>
            <div *ngIf="helperService.getObjectByName(firm.fields, 'groupSelectablesMapping')?.value"
                 [ngClass]="helperService.getObjectByName(firm.fields, 'groupSelectablesMapping').visible?  'shown': 'hidden'"
                 class="mb-5">
              <!-- auswählbare Gruppen -->
              <div id="accordion-flush-heading-3">
                <button type="button"
                        (click)="groupSelectablesMappingShown =!groupSelectablesMappingShown"
                        class="flex items-center justify-between w-full py-5 text-left text-gray-700 border-b border-gray-200 text-sm"
                        data-accordion-target="#accordion-flush-body-3" aria-expanded="true"
                        aria-controls="accordion-flush-body-3">
                  <div class="flex items-center">
                    <span class="h2-header">{{'FIRMS.SELECTABLE_GROUPS' | translate }}</span>
                    <span
                      *ngIf="helperService.getObjectByName(firm.fields, 'groupSelectablesMapping')?.value.customSettings"
                      class="text-wrapper ml-2"> ({{'FIRMS.DIFFERENT_SETTINGS' | translate}})</span>
                    <span class="group ml-2">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                           stroke="currentColor" class="w-6 h-6 hover:text-highlight">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"/>
                      </svg>
                      <div
                        class="group-hover:visible invisible absolute mt-[-5.5rem] ml-[-5.5rem] pl-4 pr-4 pt-2 pb-2 bg-gray-100 max-w-[20rem] rounded-lg text-center text-highlight shadow">
                     {{'FIRMS.INFO_SELECTABLE_GROUPS' | translate}}
                      </div>
                    </span>
                  </div>
                  <svg [ngClass]="{ 'rotate-180' : groupSelectablesMappingShown}"
                       class="w-6 h-6 shrink-0 transition-all duration-500" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"></path>
                  </svg>
                </button>
              </div>
              <div id="accordion-flush-body-3"
                   class="transition-all duration-500"
                   [ngClass]="groupSelectablesMappingShown?  'opacity-100 max-h-80' : 'opacity-0 max-h-0 overflow-hidden'"
                   aria-labelledby="accordion-flush-heading-3">
                <app-compare-lists
                  [checkDuplicatetPseudoRealm]="true"
                  [showDetails]="true"
                  [showRequiredGroups]="helperService.checkMultipleRequiredGroups(helperService.getObjectByName(firm.fields, 'groupSelectablesMapping').value)"
                  [readOnly]="helperService.getObjectByName(firm.fields, 'groupSelectablesMapping').readOnly"
                  [leftList]="helperService.getObjectByName(firm.fields, 'groupSelectablesMapping').value.active? helperService.getObjectByName(firm.fields, 'groupSelectablesMapping').value.active :  []"
                  [rightList]="helperService.getObjectByName(firm.fields, 'groupSelectablesMapping').value.inactive? helperService.getObjectByName(firm.fields, 'groupSelectablesMapping').value.inactive :  []"
                  [leftTitle]="translate.instant('FIRMS.ASSIGNED_GROUPS')"
                  [rightTitle]="translate.instant('FIRMS.POSSIBLE_GROUPS')"
                  [link]="'../../groups/'"
                  (OnUpdatetLists)="changeGroupMapping(firm, $event, 'groupSelectablesMapping')"></app-compare-lists>
              </div>
            </div>
            <div *ngIf="helperService.getObjectByName(firm.fields, 'groupMapping')?.value"
                 [ngClass]="helperService.getObjectByName(firm.fields, 'groupMapping').visible?  'shown': 'hidden'"
                 class="mb-5">
              <!-- Gruppen -->
              <div id="accordion-flush-heading-4">
                <button type="button"
                        (click)="groupMappingShown =!groupMappingShown"
                        class="flex items-center justify-between w-full py-5 text-left text-gray-700 border-b border-gray-200 text-sm"
                        data-accordion-target="#accordion-flush-body-4" aria-expanded="true"
                        aria-controls="accordion-flush-body-4">
                  <div class="flex items-center">
                    <span class="h2-header">{{'FIRMS.GROUPS' | translate }}</span>
                    <span
                      *ngIf="helperService.getObjectByName(firm.fields, 'groupMapping')?.value.customSettings"
                      class="text-wrapper ml-2"> ({{'FIRMS.DIFFERENT_SETTINGS' | translate}})</span>
                  </div>
                  <svg [ngClass]="{ 'rotate-180' : groupMappingShown}"
                       class="w-6 h-6 shrink-0 transition-all duration-500" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"></path>
                  </svg>
                </button>
              </div>
              <div id="accordion-flush-body-4"
                   class="transition-all duration-500"
                   [ngClass]="groupMappingShown?  'opacity-100 max-h-80' : 'opacity-0 max-h-0 overflow-hidden'"
                   aria-labelledby="accordion-flush-heading-4">
                <app-compare-lists
                  [checkDuplicatetPseudoRealm]="true"
                  [showDetails]="true"
                  [showRequiredGroups]="helperService.checkMultipleRequiredGroups(helperService.getObjectByName(firm.fields, 'groupMapping').value)"
                  [readOnly]="helperService.getObjectByName(firm.fields, 'groupMapping').readOnly"
                  [leftList]="helperService.getObjectByName(firm.fields, 'groupMapping').value.active? helperService.getObjectByName(firm.fields, 'groupMapping').value.active :  []"
                  [rightList]="helperService.getObjectByName(firm.fields, 'groupMapping').value.inactive? helperService.getObjectByName(firm.fields, 'groupMapping').value.inactive :  []"
                  [leftTitle]="translate.instant('FIRMS.ASSIGNED_GROUPS')"
                  [rightTitle]="translate.instant('FIRMS.POSSIBLE_GROUPS')"
                  [link]="'../../groups/'"
                  (OnUpdatetLists)="changeGroupMapping(firm, $event, 'groupMapping')"></app-compare-lists>
              </div>
            </div>
            <div *ngIf="helperService.getObjectByName(firm.fields, 'defaultGroupMapping')?.value"
                 [ngClass]="helperService.getObjectByName(firm.fields, 'defaultGroupMapping').visible?  'shown': 'hidden'"
                 class="mb-5">
              <!-- Standardgruppen -->
              <div id="accordion-flush-heading-5">
                <button type="button"
                        (click)="defaultGroupMappingShown =!defaultGroupMappingShown"
                        class="flex items-center justify-between w-full py-5 text-left text-gray-700 border-b border-gray-200 text-sm"
                        data-accordion-target="#accordion-flush-body-5" aria-expanded="true"
                        aria-controls="accordion-flush-body-5">
                  <div class="flex items-center">
                    <span class="h2-header">{{'FIRMS.DEFAULT_GROUPS' | translate }}</span>
                    <span
                      *ngIf="helperService.getObjectByName(firm.fields, 'defaultGroupMapping')?.value.customSettings"
                      class="text-wrapper ml-2"> ({{'FIRMS.DIFFERENT_SETTINGS' | translate}})</span>
                    <span class="group ml-2">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                           stroke="currentColor" class="w-6 h-6 hover:text-highlight">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"/>
                      </svg>
                      <div
                        class="group-hover:visible invisible absolute mt-[-5.5rem] ml-[-5.5rem] pl-4 pr-4 pt-2 pb-2 bg-gray-100 max-w-[20rem] rounded-lg text-center text-highlight shadow">
                     {{'FIRMS.INFO_DEFAULT_GROUPS' | translate}}
                      </div>
                    </span>
                  </div>
                  <svg [ngClass]="{ 'rotate-180' : defaultGroupMappingShown}"
                       class="w-6 h-6 shrink-0 transition-all duration-500" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"></path>
                  </svg>
                </button>
              </div>
              <div id="accordion-flush-body-5"
                   class="transition-all duration-500"
                   [ngClass]="defaultGroupMappingShown?  'opacity-100 max-h-80' : 'opacity-0 max-h-0 overflow-hidden'"
                   aria-labelledby="accordion-flush-heading-5">
                <app-compare-lists
                  [checkDuplicatetPseudoRealm]="true"
                  [showDetails]="true"
                  [showRequiredGroups]="helperService.checkMultipleRequiredGroups(helperService.getObjectByName(firm.fields, 'defaultGroupMapping').value)"
                  [link]="'../../groups/'"
                  [readOnly]="helperService.getObjectByName(firm.fields, 'defaultGroupMapping').readOnly"
                  [leftList]="helperService.getObjectByName(firm.fields, 'defaultGroupMapping').value.active? helperService.getObjectByName(firm.fields, 'defaultGroupMapping').value.active :  []"
                  [rightList]="helperService.getObjectByName(firm.fields, 'defaultGroupMapping').value.inactive? helperService.getObjectByName(firm.fields, 'defaultGroupMapping').value.inactive :  []"
                  [leftTitle]="translate.instant('FIRMS.ASSIGNED_GROUPS')"
                  [rightTitle]="translate.instant('FIRMS.POSSIBLE_GROUPS')"
                  (OnUpdatetLists)="helperService.getObjectByName(firm.fields, 'defaultGroupMapping').value.active = $event.leftList; helperService.getObjectByName(firm.fields, 'defaultGroupMapping').value.inactive = $event.rightList;"></app-compare-lists>
              </div>
            </div>

            <div *ngIf="currentId && !isNewSubfirm" class="mb-5">
              <div id="accordion-flush-heading-6">
                <button type="button"
                        (click)="userMappingShown =!userMappingShown"
                        class="flex items-center justify-between w-full py-5 text-left text-gray-700 border-b border-gray-200 text-sm"
                        data-accordion-target="#accordion-flush-body-5" aria-expanded="true"
                        aria-controls="accordion-flush-body-5">
                  <div class="flex items-center">
                    <span class="h2-header">{{'FIRMS.USERS' | translate }}</span>
                  </div>
                  <svg [ngClass]="{ 'rotate-180' : userMappingShown}"
                       class="w-6 h-6 shrink-0 transition-all duration-500" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"></path>
                  </svg>
                </button>
              </div>
              <div id="accordion-flush-body-6"
                   class="transition-all duration-500"
                   [ngClass]="userMappingShown?  'opacity-100 max-h-80' : 'opacity-0 max-h-0 overflow-hidden'"
                   aria-labelledby="accordion-flush-heading-6">
                <div class="flex justify-end my-3">
                  <button type="button" class="button-highlight"
                          [queryParams]="{ assignedFirm: currentId}"
                          [routerLink]="['/users/new']">
                    {{'FIRMS.NEW_USER' | translate}}
                  </button>
                </div>
                <div class="table-header-row">
                  <div class="w-2/12 table-column">
                    <h2> {{'GENERAL.LIST.ACTIVE' | translate}}</h2>
                  </div>
                  <div class="w-3/12 table-column">
                    <h2> {{'USERS.LASTNAME' | translate}}</h2>
                  </div>
                  <div class="w-3/12 table-column">
                    <h2> {{'USERS.FIRSTNAME' | translate}}</h2>
                  </div>
                  <div class="w-3/12 table-column">
                    <h2> {{'USERS.USERNAME' | translate}}</h2>
                  </div>
                  <div class="w-1/12 table-column">
                  </div>
                </div>
                <div class="overflow-y-auto max-h-[12rem]">
                  <div *ngFor="let user of helperService.getObjectByName(firm.fields, 'users').value; let last = last"
                       class="table-row cursor-pointer border-b"
                       [routerLink]="['../../users/'+user.id]"
                       [ngClass]="{'rounded-b-md' : last}">
                    <div class="w-2/12 table-column">
                      <input type="checkbox" class="input-checkbox"
                             [checked]="user.active"
                             [disabled]="true">
                    </div>
                    <div class="w-3/12 table-column">
                      <div>
                        {{user?.lastName}}
                      </div>
                    </div>
                    <div class="w-3/12 table-column">
                      <div>
                        {{user?.firstName}}
                      </div>
                    </div>
                    <div class="w-3/12 table-column">
                      <div>
                        {{user?.username}}
                      </div>
                    </div>
                    <div class="w-1/12 table-column">
                      <svg [routerLink]="['../../users/'+user.id]"
                           xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                           stroke="currentColor" class="w-6 h-6 hover:text-highlight">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"/>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="showReloactionModal">
    <app-relocation-modal
      (toggleModal)="showReloactionModal = false"
      (returnObject$)="relocate($event)"
      [firms]="availableFirms"
      [textObject]="{header: 'FIRMS.RELOCATION', text: 'FIRMS.RELOCATION_WARN'}">
    </app-relocation-modal>
  </ng-container>
  <ng-container *ngIf="showDefaultModal">
    <app-default-modal
      [btnName]="'FIRMS.RESET'"
      (toggleModal)="showDefaultModal = false"
      (returnObject$)="updateClientToParent()"
      [textObject]="{header: 'FIRMS.CONFIRM_RESET_TO_PARENT', text: 'FIRMS.CONFIRM_RESET_TO_PARENT_INFO_ALL'}">
    </app-default-modal>
  </ng-container>
</ng-container>
