import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AdminRestService} from "../../services/admin-rest.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-import-modal',
  templateUrl: './import-modal.component.html'
})
export class ImportModalComponent implements OnInit {

  @Output() toggleModal = new EventEmitter<boolean>();
  public importFile: File;
  public selectedFileName: string ='';


  constructor(private adminRestService: AdminRestService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  public importModalClose(): void {
    this.toggleModal.emit(false);
  }

  public changeListener(files: FileList) {
    if (files && files.length > 0) {
      this.selectedFileName= files.item(0).name;
      this.importFile = files.item(0);
    } else {
      this.importFile = null;
      this.selectedFileName='';
    }
  }

  public uploadImportFile(): void {
    this.adminRestService.uploadImport(this.importFile)
      .subscribe({
        next: () => {
          this.toastr.success(this.translate.instant('MESSAGES.SUCCESS.DATA'));
        }, error: () => {
          this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA_SAVE'));
        }
      })
  }

}
