import {Component, Input, OnInit} from '@angular/core';
import {IUserRoles} from "../../interfaces/user.roles";

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html'
})
export class UserRolesComponent implements OnInit {

  @Input() public userRoles: IUserRoles[];


  constructor() {
  }

  ngOnInit(): void {
  }

}
