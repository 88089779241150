<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div (click)="modalClose()" class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div (click)="$event.stopPropagation()"
        class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 max-w-3xl">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div
              class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="h-6 w-6 text-sso-red" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title">{{textObject.header| translate}}</h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">{{ textObject.text | translate}}</p>
                <pre *ngIf="jsonObject">
                     {{JSON.stringify(JSON.parse(jsonObject), null, '\t')}}
                </pre>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button *ngIf="!hideButton" (click)="emitObject(); modalClose()" type="button"
                  class="button-red mx-1">
            <span *ngIf="!btnName">
              {{'GENERAL.DELETE' | translate}}
            </span>
            <span *ngIf="btnName">
              {{btnName | translate}}
            </span>
          </button>
          <button (click)="modalClose()" type="button"
                  class="button-gray mx-1">
            {{'GENERAL.CANCEL' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
