import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../services/user.service';
import {UserRestService} from '../../services/user-rest.service';
import {IObject} from '../../interfaces/field.interface';
import {HelperService} from '../../services/helper.service';
import {AuthService} from '../../auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-user-overview',
  templateUrl: './user-overview.component.html'
})

export class UserOverviewComponent implements OnInit, OnDestroy {
  public Subscriptions: Subscription[] = [];
  public users: IObject[];
  public selectedUser = {} as IObject;
  public showNoResults: boolean = false;
  public showDeleteModal = false;
  public allowDeletion: boolean = false;
  public allowCreation: boolean = false;
  public allowImpersonation: boolean = false;
  public pageInfo: any = {
    currentPage: 0,
    searchTerm: '',
    totalPages: undefined,
    firstPage: undefined,
    lastPage: undefined,
  }
  public showCustomToastMessage: boolean;
  public changedUserArray: IObject [] = [];
  public overViewColumns: string[];

  constructor(public userService: UserService,
              public helperService: HelperService,
              public userRestService: UserRestService,
              private translate: TranslateService,
              public route: ActivatedRoute,
              public router: Router,
              private cookieService: CookieService,
              private toastr: ToastrService,
              public authService: AuthService) {
  }

  ngOnInit(): void {
    this.Subscriptions.push(this.authService.outline$.subscribe(res => {
      if (res) {
        this.allowDeletion = this.helperService.findObjectByName(res, 'USERS')?.allowDeletion;
        this.allowCreation = this.helperService.findObjectByName(res, 'USERS')?.allowCreation;
        this.overViewColumns = this.helperService.findObjectByName(res, 'USERS')?.overviewColumns;
        this.allowImpersonation = res.some((x) => x.name === 'IMPERSONATION') && environment.showImpersonation;
 
      }
    }));

    this.Subscriptions.push(this.route
      .queryParams
      .subscribe(params => {
        if (Object.keys(params).length !== 0) {
          this.pageInfo.currentPage = params.page ? parseInt(params.page) : 0;
          this.pageInfo.searchTerm = params.searchTerm ? params.searchTerm : '';
          this.cookieService.set('searchterm_users', this.pageInfo.searchTerm);
          this.userService.getUsers(this.pageInfo.searchTerm, this.pageInfo.currentPage);
        } else {
          this.cookieService.set('searchterm_users', '');
          this.cookieService.set('show_users', 'false');
          this.userService.getUsers('', 0);
        }

      }));

    this.Subscriptions.push(this.userService.users$.subscribe(data => {
      if (data) {
        this.pageInfo.firstPage = data.first;
        this.pageInfo.lastPage = data.last;
        this.pageInfo.totalPages = data.totalPages;
        this.pageInfo.totalElements = data.totalElements;
        this.users = data.content;
        this.showNoResults = false;
      }
      if (data && data.empty) {
        this.showNoResults = true;
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.Subscriptions) {
      this.Subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

  public checkVisibility(input: string): boolean {
    return this.overViewColumns.some(x => x === input);
  }


  public changeActive($event: any, user: IObject): void {
    this.showCustomToastMessage = true;
    $event.preventDefault();
    $event.stopPropagation();
    this.helperService.getObjectByName(user.fields, 'active').value = !this.helperService.getObjectByName(user.fields, 'active').value;
    const exists = this.users.find(x => this.helperService.getObjectByName(x.fields, 'id').value === this.helperService.getObjectByName(user.fields, 'id').value);
    if (exists) {
      const index = this.changedUserArray.findIndex(x => this.helperService.getObjectByName(x.fields, 'id').value === this.helperService.getObjectByName(user.fields, 'id').value);
      if (index === -1) {
        this.changedUserArray.push(user);
        //adding marked attribute, to highlight in list which users are selected
        user.marked = true;
      } else {
        user.marked = false;
        this.changedUserArray.splice(index, 1);
      }
    }
  }

  public reloadPageParameters(): void {
    this.router.navigate(['/users'], {
      queryParams: {
        searchTerm: this.pageInfo.searchTerm,
        page: (this.pageInfo.currentPage - 1) < 0 ? 0 : (this.pageInfo.currentPage - 1),
        now: Date.now()
      }
    }).then();
    this.changedUserArray = [];
  }


  public updateUserList(): void {
    // remove marked attribute from user array
    const userArray = this.changedUserArray.map(({marked, ...item}) => item);
    this.userService.showErrorPageUsers$.next('LOADING');
    this.userRestService.updateUserActive(userArray, this.pageInfo.searchTerm, (this.pageInfo.currentPage - 1) < 0 ? 0 : (this.pageInfo.currentPage - 1))
      .subscribe({
        next: (data) => {
          if (data) {
            this.pageInfo.firstPage = data.first;
            this.pageInfo.lastPage = data.last;
            this.pageInfo.totalPages = data.totalPages;
            this.pageInfo.currentPage = data.number;
            this.users = data.content;
          }
          this.userService.showErrorPageUsers$.next('CONTENT_USERS')
          this.toastr.success(this.translate.instant('MESSAGES.SUCCESS.DATA'));
        },
        error: (error) => {
          this.userService.showErrorPageUsers$.next('ERROR_USERS')
          if (error.status == 422 && error.error.message == 'can not deactivate principal') {
            this.toastr.error(this.translate.instant('MESSAGES.ERROR.DEACTIVATE_OWN_USER'));
          } else {
            this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA_SAVE'));
          }
        }
      });
    this.changedUserArray = [];
  }


  public deleteUser(id: string): void {
    this.userRestService.deleteUser(id).subscribe({
      next: () => {
        const index = this.users.findIndex(user => this.helperService.getObjectByName(user.fields, 'id').value === id);
        if (index >= 0) {
          this.users.splice(index, 1);
        }
        this.toastr.success(this.translate.instant('MESSAGES.SUCCESS.DATA'));
      },
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA_SAVE'));
      }
    })
  }

  public impersonate(userId: string) {
    this.userService.impersonate(userId, environment.realmConfig)
  }
}
