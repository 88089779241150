import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {ClientRestService} from './client-rest.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {IObject} from '../interfaces/field.interface';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  public showErrorPageClients$: BehaviorSubject<string> = new BehaviorSubject<string>('LOADING');
  public clients$: BehaviorSubject<IObject[]> = new BehaviorSubject<IObject[]>(null);
  public client$: Subject<IObject> = new Subject<IObject>();

  constructor(private clientRestService: ClientRestService,
              private translate: TranslateService,
              private toastr: ToastrService
  ) {
  }


  public getClients(): void {
    this.showErrorPageClients$.next('LOADING');
    this.clientRestService.getClients().subscribe({
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA'));
        this.showErrorPageClients$.next('ERROR_CLIENTS');
      },
      next: (response) => {
        response ? this.clients$.next(response) : this.clients$.next([]);
        this.showErrorPageClients$.next('CONTENT_CLIENTS');
      }
    })
  }

  public getClientById(id: string): void {
    this.showErrorPageClients$.next('LOADING');
    this.clientRestService.getClientById(id).subscribe({
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA'));
        this.showErrorPageClients$.next('ERROR_CLIENT');
      },
      next: (response) => {
        response ? this.client$.next(response) : this.client$.next(null);
        this.showErrorPageClients$.next('CONTENT_CLIENT');
      }
    })
  }

  public getClientStructure(): void {
    this.showErrorPageClients$.next('LOADING');
    this.clientRestService.getClientStructure().subscribe({
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA'));
        this.showErrorPageClients$.next('ERROR_CLIENT');
      },
      next: (response) => {
        response ? this.client$.next(response) : this.client$.next(null);
        this.showErrorPageClients$.next('CONTENT_CLIENT');
      }
    })
  }

}
