import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {UserRestService} from './user-rest.service';
import {IObject, IPage} from '../interfaces/field.interface';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public showErrorPageUsers$: BehaviorSubject<string> = new BehaviorSubject<string>('LOADING');
  public users$: BehaviorSubject<IPage> = new BehaviorSubject<IPage>(null);
  public user$: Subject<IObject> = new Subject<IObject>();
  public userRoles$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private userRestService: UserRestService,
              private translate: TranslateService,
              private toastr: ToastrService
  ) {
  }


  public getUsers(searchTerm: string, page: number): void {
    this.showErrorPageUsers$.next('LOADING');
    this.userRestService.getUsers(searchTerm, page).subscribe({
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA'));
        this.showErrorPageUsers$.next('ERROR_USERS');
      },
      next: (response) => {
        response ? this.users$.next(response) : this.users$.next(null);
        this.showErrorPageUsers$.next('CONTENT_USERS');
      }
    })
  }

  public getUserById(id: string): void {
    this.showErrorPageUsers$.next('LOADING');
    this.userRestService.getUserById(id).subscribe({
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA'));
        this.showErrorPageUsers$.next('ERROR_USER');
      },
      next: (response) => {
        response ? this.user$.next(response) : void 0;
        this.showErrorPageUsers$.next('CONTENT_USER');
      }
    })
  }

  public getUserRolesById(id: string): void {
    this.showErrorPageUsers$.next('LOADING');
    this.userRestService.getUserRolesById(id).subscribe({
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA'));
        this.showErrorPageUsers$.next('ERROR_USER_ROLES');
      },
      next: (response) => {
        response ? this.userRoles$.next(response) : this.userRoles$.next(null);
        this.showErrorPageUsers$.next('CONTENT_USER_ROLES');
      }
    })
  }

  public getUserStructure(): void {
    this.showErrorPageUsers$.next('LOADING');
    this.userRestService.getUserStructure().subscribe({
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA'));
        this.showErrorPageUsers$.next('ERROR_USER');
      },
      next: (response) => {
        response ? this.user$.next(response) : this.user$.next(null);
        this.showErrorPageUsers$.next('CONTENT_USER');
      }
    })
  }

  public impersonate(userId: string, realm: string) {
    this.userRestService.getAccessToken().subscribe({
      error: (error) => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.IMPERSONATE'));
      },
      next: (response) => {
        this.userRestService.impersonate(userId, realm, response).subscribe({
          error: (error) => {
            this.toastr.error(this.translate.instant('MESSAGES.ERROR.IMPERSONATE'));
          },
          next: (response) => {
            this.toastr.success(this.translate.instant('MESSAGES.SUCCESS.IMPERSONATE'));
            if(environment.impersonationRedirectUrl) {
              window.open(environment.impersonationRedirectUrl, "_blank")
            }
          }
        })
      }
    })
  }

}
