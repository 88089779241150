import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {IPage} from "../interfaces/field.interface";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {ImportLogRestService} from "./import-log-rest.service";

@Injectable({
  providedIn: 'root'
})
export class ImportLogService {
  public showErrorPageImportLog$: BehaviorSubject<string> = new BehaviorSubject<string>('LOADING');
  public logs$: BehaviorSubject<IPage> = new BehaviorSubject<IPage>(null);

  constructor(private translate: TranslateService,
              private importLogRestService: ImportLogRestService,
              private toastr: ToastrService) {
  }

  public getImportLogs(type: string, page: number, f?: string, t?: string): void {
    this.showErrorPageImportLog$.next('LOADING');
    this.importLogRestService.getImportLogs(type, page, f, t).subscribe({
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA'));
        this.showErrorPageImportLog$.next('ERROR_IMPORT_LOGS');
      },
      next: (response) => {
        response ? this.logs$.next(response) : this.logs$.next(null);
        this.showErrorPageImportLog$.next('CONTENT_IMPORT_LOGS');
      }
    })
  }
}
