<div class="root flex h-screen">
  <!-- SIDEBAR -->
  <aside class="w-[25rem] h-full" aria-label="Sidebar">
    <div class="py-4 px-3 sidebar-bg h-full w-full inline-block relative">
      <div class="ml-[1rem] mr-[1rem] cursor-pointer bg-companyLogo bg-no-repeat bg-contain bg-center max-w-[15rem] h-[4rem] !cursor-default"
        routerLink="/"></div>
      <div class="cursor-default my-2">
        <span class="ml-3 text-sidebar-text-color">{{'SIDEBAR.WELCOME' | translate}} &nbsp;</span>
        <span class="text-highlight">{{authService.username$ |async}}</span>
      </div>
      <li *ngIf="showRealm">
        <div class="cursor-default">
          <select *ngIf="showRealmSelect" name="realmSelector" class="input-field w-full" id="realmSelector"
                #realmSelector="ngModel"
                [(ngModel)]="selectedRealm"
                (change)='onRealmChange(selectedRealm)'>
          <option *ngFor="let realm of realmNames"
                  [value]="realm"
                  [selected]="realm === selectedRealm">
            {{realm}}</option>
        </select>
        </div>
      </li>
      <!-- NAVIGATION -->
      <ng-container>
        
        <ul class="space-y-2 overflow-y-auto pt-5">
          <li *ngIf="checkPermissionOutline('CLIENTS')">
            <a routerLink="clients" routerLinkActive="active"
               class="group a-sidebar">
              <svg
                class="flex-shrink-0 w-6 h-6 text-sidebar-text-color transition duration-75 group-hover:text-highlight"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"/>
              </svg>
              <span class="ml-3 text-sidebar-text-color text-sm">{{'SIDEBAR.APPLICATIONS' | translate}}</span>
            </a>
          </li>
          <li *ngIf="checkPermissionOutline('GROUPS')">
            <a routerLink="groups" routerLinkActive="active"
               class="group a-sidebar">
              <svg
                class="flex-shrink-0 w-6 h-6 text-sidebar-text-color transition duration-75 group-hover:text-highlight"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"/>
              </svg>
              <span class="ml-3 text-sidebar-text-color text-sm">{{'SIDEBAR.GROUPS' | translate}}</span>
            </a>
          </li>
          <li *ngIf="checkPermissionOutline('FIRMS')">
            <a routerLink="firms" routerLinkActive="active"
               class="group a-sidebar">
              <svg
                class="flex-shrink-0 w-6 h-6 text-sidebar-text-color transition duration-75 group-hover:text-highlight"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"/>
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"/>
              </svg>
              <span class="ml-3 text-sidebar-text-color text-sm">{{'SIDEBAR.FIRMS' | translate}}</span>
            </a>
          </li>
          <li *ngIf="checkPermissionOutline('USERS')">
            <a routerLink="users" routerLinkActive="active"
               class="group a-sidebar">
              <svg
                class="flex-shrink-0 w-6 h-6 text-sidebar-text-color transition duration-75 group-hover:text-highlight"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"/>
              </svg>
              <span class="ml-3 text-sidebar-text-color text-sm">{{'SIDEBAR.USERS' | translate}}</span>
            </a>
          </li>
          <li *ngIf="checkPermissionOutline('EVENTS')">
            <a routerLink="events" routerLinkActive="active"
               class="group a-sidebar">
              <svg
                class="flex-shrink-0 w-6 h-6 text-sidebar-text-color transition duration-75 group-hover:text-highlight"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"/>
              </svg>
              <span class="ml-3 text-sidebar-text-color text-sm">{{'SIDEBAR.EVENTS' | translate}}</span>
            </a>
          </li>
          <li *ngIf="checkPermissionOutline('IMPORT_LOGS')">
            <a routerLink="import-log" routerLinkActive="active"
               class="group a-sidebar">
              <svg
                class="flex-shrink-0 w-6 h-6 text-sidebar-text-color transition duration-75 group-hover:text-highlight"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"/>
              </svg>
              <span class="ml-3 text-sidebar-text-color text-sm">{{'SIDEBAR.IMPORT_LOG' | translate}}</span>
            </a>
          </li>
        </ul>
      </ng-container>
      <div
        class="w-[calc(100%-1.5rem)] flex absolute bottom-0 border-t-sidebar-border border-t-2">
        <ul class="space-y-2 mt-2 mb-2 w-full">
          <li *ngIf="checkPermissionOutline('IMPORT')">
            <a class="group a-sidebar"
               (click)="toggleImportModal()">
              <svg
                class="flex-shrink-0 w-6 h-6 text-sidebar-text-color transition duration-75 group-hover:text-highlight"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m0-3l-3-3m0 0l-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"/>
              </svg>
              <span
                class="flex-1 ml-3 text-sidebar-text-color whitespace-nowrap text-sm">{{'SIDEBAR.IMPORT' | translate}}</span>
            </a>
          </li>
          <li *ngIf="checkPermissionOutline('EXPORT')">
            <a class="group a-sidebar"
               (click)="toggleExportModal()">
              <svg
                class="flex-shrink-0 w-6 h-6 text-sidebar-text-color transition duration-75 group-hover:text-highlight"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"/>
              </svg>
              <span
                class="flex-1 ml-3 text-sidebar-text-color whitespace-nowrap text-sm">{{'SIDEBAR.EXPORT' | translate}}</span>
            </a>
          </li>
          <li *ngIf="checkPermissionOutline('HELP')">
            <a class="group a-sidebar" (click)="openHelp()">
              <svg
                class="flex-shrink-0 w-6 h-6 text-sidebar-text-color transition duration-75 group-hover:text-highlight"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"/>
              </svg>
              <span
                class="flex-1 ml-3 text-sidebar-text-color whitespace-nowrap text-sm">{{'SIDEBAR.HELP' | translate}}</span>
            </a>
          </li>
          <li (click)="authService.logout()">
            <a class="group a-sidebar">
              <svg
                class="flex-shrink-0 w-6 h-6 text-sidebar-text-color-2 transition duration-75 group-hover:text-highlight"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"/>
              </svg>
              <span
                class="flex-1 ml-3 text-sidebar-text-color-2 whitespace-nowrap text-sm">{{'SIDEBAR.LOGOUT' | translate}}</span>
            </a>
          </li>
          <li>
            <span *ngIf="!showRealmSelect" class="ml-3 text-sidebar-text-color-2 text-sm">{{realmName}}</span>
          </li>
        </ul>
      </div>
    </div>
  </aside>

  <!-- MODAL -->
  <ng-container *ngIf="showImportModal">
    <app-import-modal
      (toggleModal)="showImportModal = false">
    </app-import-modal>
  </ng-container>
  <ng-container *ngIf="showExportModal">
    <app-export-modal
      (toggleModal)="showExportModal = false">
    </app-export-modal>
  </ng-container>

  <!-- CONTENT -->
  <div class="h-full w-full bg-gray-100 overflow-x-hidden">
    <router-outlet></router-outlet>
  </div>
</div>
