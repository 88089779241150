import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {IObject} from '../interfaces/field.interface';
import {HelperService} from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class ClientRestService {

  constructor(private http: HttpClient,
  private helperService: HelperService) {
  }

  public getClients(): Observable<IObject[]> {
    return this.http.get<IObject[]>(
      `${environment.apiUrl}/client/${environment.realmConfig}`
    );
  }

  public getClientStructure(): Observable<IObject> {
    return this.http.get<IObject>(
      `${environment.apiUrl}/client/${environment.realmConfig}/structure`
    );
  }

  public getClientById(id: string): Observable<any> {
    return this.http.get<IObject>(
      `${environment.apiUrl}/client/${environment.realmConfig}/${id}`
    );
  }


  public deleteClient(id: string) {
    return this.http.delete(
      `${environment.apiUrl}/client/${environment.realmConfig}/${id}`
    );
  }


  public updateClient(client: IObject) {
    return this.http.put<IObject>(
      `${environment.apiUrl}/client/${environment.realmConfig}/${this.helperService.getObjectByName(client.fields, 'idOfClient').value}`, client
    );
  }

  public createClient(client: IObject) {
    return this.http.post<IObject>(
      `${environment.apiUrl}/client/${environment.realmConfig}`, client
    );
  }

}
