<ng-container *ngIf="(userService.showErrorPageUsers$ | async) === 'ERROR_USER'">
  <app-default-error-page></app-default-error-page>
</ng-container>

<ng-container *ngIf="(userService.showErrorPageUsers$ | async) === 'LOADING'">
  <app-default-loading-page></app-default-loading-page>
</ng-container>

<ng-container *ngIf="(userService.showErrorPageUsers$ | async) === 'CONTENT_USER'">
  <div class="card-header-div">
    <h1 class="h1-header mr-4 flex-1 flex items-center">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="w-6 h-6 cursor-pointer mr-1" [routerLink]="['../']">
        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
      </svg>
      <span>
        {{'USERS.HEADER' | translate}} &nbsp;</span>
      <span *ngIf="currentId">- {{helperService.getObjectByName(user.fields, 'username').value}}</span>
      <span *ngIf="!currentId">- {{'USERS.NEW_USER' | translate}}</span>
    </h1>
    <div class="flex flex-1 justify-end gap-x-2">
      <button *ngIf="allowImpersonation && currentId" class="button-highlight" type="submit"
              (click)="impersonate()"
              [disabled]="false">
              {{'USERS.IMPERSONATE' | translate}}
      </button>
      <button class="button-highlight" type="submit"
              (click)="onSubmit($event, !userForm.invalid, 'DATA')"
              [disabled]="userForm.invalid">
          <span *ngIf="loading" role="status">
              <svg aria-hidden="true" class="mr-2 w-4 h-4 animate-spin fill-highlight" viewBox="0 0 100 101" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"/>
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"/>
              </svg>
              <span class="sr-only">Loading...</span>
            </span>
        {{'USERS.SAVE_USER' | translate}}
      </button>
    </div>
  </div>

  <div class="p-5">
    <div class="flex flex-col lg:flex-row">
      <div class="w-full max-lg:mb-4 lg:mr-4 lg:w-1/3 ">
        <div class="card-div overflow-y-auto max-h-[calc(100vh-7.5rem)]">
          <ul class="flex flex-wrap mb-8 text-sm font-medium text-center inline-flex" id="myTab" role="tablist">
            <!-- Data TAB -->
            <li class="mr-2" role="presentation">
              <button (click)="changeSelectedType('DATA')"
                      class="inline-block p-4 rounded-t-lg border-b-2 hover:border-highlight text-slate-500"
                      [ngClass]="{'border-highlight': selectedType === 'DATA', 'border-transparent': selectedType !== 'DATA'}"
                      type="button" role="tab">
                <span class="h2-header">{{'GENERAL.DATA' | translate}}</span>
              </button>
            </li>
            <!-- Zusatzinfos TAB -->
            <li class="mr-2" role="presentation" *ngIf="currentId">
              <button (click)="changeSelectedType('ATTRIBUTES')"
                      class="inline-block p-4 rounded-t-lg border-b-2 hover:border-highlight text-slate-500"
                      [ngClass]="{'border-highlight': selectedType === 'ATTRIBUTES', 'border-transparent': selectedType !== 'ATTRIBUTES'}"
                      type="button" role="tab">
                <span class="h2-header">{{'USERS.ATTRIBUTES' | translate}}</span>
              </button>
            </li>
            <!-- Rollen TAB -->
            <li class="mr-2" role="presentation" *ngIf="currentId">
              <button (click)="changeSelectedType('ROLES')"
                      class="inline-block p-4 rounded-t-lg border-b-2 hover:border-highlight text-slate-500"
                      [ngClass]="{'border-highlight': selectedType === 'ROLES', 'border-transparent': selectedType !== 'ROLES'}"
                      type="button" role="tab">
                <span class="h2-header">{{'USERS.ROLES' | translate}}</span>
              </button>
            </li>
          </ul>
          <form #userForm="ngForm">
            <ng-container *ngIf="selectedType === 'DATA'">
              <div [ngClass]="helperService.getObjectByName(user.fields, 'active').visible? 'shown': 'hidden'"
                   class="flex items-center">
                <input [checked]="helperService.getObjectByName(user.fields, 'active').value"
                       (change)="helperService.getObjectByName(user.fields, 'active').value = !helperService.getObjectByName(user.fields, 'active').value"
                       [disabled]="helperService.getObjectByName(user.fields, 'active').readOnly"
                       class="input-checkbox" id="active" type="checkbox">
                <label class="label-checkbox" for="active">{{'USERS.ACTIVE' | translate}}</label>
              </div>
              <div [ngClass]="helperService.getObjectByName(user.fields, 'username').visible? 'shown': 'hidden'">
                <label for="username" class="label">
                  {{'USERS.USERNAME' | translate}}*
                </label>
                <input class="input-field w-full" id="username"
                       type="text" [(ngModel)]="helperService.getObjectByName(user.fields, 'username').value"
                       name="username" #username="ngModel" required
                       [disabled]="helperService.getObjectByName(user.fields, 'username').readOnly"
                       (keydown)="keyDownFunction($event, userForm.valid, 'DATA')"
                       [ngClass]="{'input-field-has-Error': username.invalid && username.touched}"
                />
                <span class="label-error-hint" *ngIf="username.invalid && username.touched">
              {{'INPUT_FIELD_ERROR.REQUIRED' | translate}}
            </span>
              </div>
              <div [ngClass]="helperService.getObjectByName(user.fields, 'firstName').visible? 'shown': 'hidden'">
                <label for="firstName" class="label">
                  {{'USERS.FIRSTNAME' | translate}}*
                </label>
                <input class="input-field w-full" id="firstName"
                       type="text" [(ngModel)]="helperService.getObjectByName(user.fields, 'firstName').value"
                       name="firstName" #firstName="ngModel" required
                       [disabled]="helperService.getObjectByName(user.fields, 'firstName').readOnly"
                       (keydown)="keyDownFunction($event, userForm.valid, 'DATA')"
                       [ngClass]="{'input-field-has-Error': firstName.invalid && firstName.touched}"
                />
                <span class="label-error-hint" *ngIf="firstName.invalid && firstName.touched">
              {{'INPUT_FIELD_ERROR.REQUIRED' | translate}}
            </span>
              </div>
              <div [ngClass]="helperService.getObjectByName(user.fields, 'lastName').visible? 'shown': 'hidden'">
                <label for="lastName" class="label">
                  {{'USERS.LASTNAME' | translate}}*
                </label>
                <input class="input-field w-full" id="lastName"
                       type="text" [(ngModel)]="helperService.getObjectByName(user.fields, 'lastName').value"
                       name="lastName" #lastName="ngModel" required
                       [disabled]="helperService.getObjectByName(user.fields, 'lastName').readOnly"
                       (keydown)="keyDownFunction($event, userForm.valid, 'DATA')"
                       [ngClass]="{'input-field-has-Error': lastName.invalid && lastName.touched}"
                />
                <span class="label-error-hint" *ngIf="lastName.invalid && lastName.touched">
              {{'INPUT_FIELD_ERROR.REQUIRED' | translate}}
            </span>
              </div>
              <div [ngClass]="helperService.getObjectByName(user.fields, 'email').visible? 'shown': 'hidden'">
                <label for="email" class="label">
                  {{'USERS.EMAIL' | translate}}*
                </label>
                <input class="input-field w-full" id="email"
                       type="email" [(ngModel)]="helperService.getObjectByName(user.fields, 'email').value"
                       name="email" #email="ngModel" required
                       pattern="(?:[a-z0-9!#$%&’*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&’*+/=?^_`{|}~-]+)*|”(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*”)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])"
                       [disabled]="helperService.getObjectByName(user.fields, 'email').readOnly"
                       (keydown)="keyDownFunction($event, userForm.valid, 'DATA')"
                       [ngClass]="{'input-field-has-Error': email.invalid && email.touched}"
                />
                <span class="label-error-hint" *ngIf="email.invalid && email.touched">
              <span *ngIf="email.value.length === 0">
                {{'INPUT_FIELD_ERROR.REQUIRED' | translate}}
                </span>
              <span *ngIf="email.value.length >0">
                {{'INPUT_FIELD_ERROR.INVALID_MAIL' | translate}}
              </span>
            </span>
              </div>
              <div *ngIf="!currentId && showAssignedFirm">
                <label for="firmSearch" class="label">
                  {{'USERS.ASSIGNED_FIRM' | translate}}*
                </label>
                <input name="firmSearch" #firmSearch="ngModel" type="text"
                       placeholder="{{'GENERAL.SEARCH' | translate}}" class="input-field w-full my-2" list="list"
                       required
                       id="firmSearch" [(ngModel)]="assignedFirm"/>
                <datalist id="list">
                  <option *ngFor="let firm of availableFirms"
                          [value]="helperService.getObjectByName(firm.fields, 'name').value + ' / ' + helperService.getObjectByName(firm.fields, 'id').value">
                    {{helperService.getObjectByName(firm.fields, 'name').value + ' / ' + helperService.getObjectByName(firm.fields, 'id').value}}
                  </option>
                </datalist>
                <span class="label-error-hint" *ngIf="firmSearch.invalid && firmSearch.touched">
                 {{'INPUT_FIELD_ERROR.REQUIRED' | translate}}
                </span>
              </div>
            </ng-container>
            <ng-container *ngIf="selectedType === 'ATTRIBUTES'">
              <ng-container
                *ngFor="let attribute of helperService.getObjectByName(user.fields, 'attributes').value; let i = index;">
                <div [ngClass]="helperService.getObjectByName(user.fields, 'attributes').visible? 'shown': 'hidden'">
                  <label for="{{attribute.displayName}}" class="label">
                    {{attribute.displayName}}
                  </label>
                  <input class="input-field w-full" id="{{attribute.name}}"
                         type="text"
                         (keydown)="keyDownFunction($event, userForm.valid, 'DATA')"
                         name="{{attribute.displayName}}"
                         #attr="ngModel" [disabled]="attribute.readOnly"
                         [(ngModel)]="attribute.value"/>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="selectedType === 'ROLES'">
              <app-default-loading-page [fullPage]="false"
                                        *ngIf="rolesLoading"></app-default-loading-page>
              <app-user-roles *ngIf="!rolesLoading" [userRoles]="userRoles"></app-user-roles>
            </ng-container>
          </form>
          <ng-container *ngIf="selectedType === 'DATA' && currentId">
            <span class="p-0.5 rounded bg-gray-200 w-1/3 my-5"></span>
            <form #passwordForm="ngForm">
              <ng-container>
                <div
                  [ngClass]="helperService.getObjectByName(userPassword.fields, 'password').visible? 'shown': 'hidden'">
                  <label for="password" class="label !flex items-center">
                    {{'USERS.PASSWORD' | translate}}*
                    <div
                      class="group hover:text-highlight hover:bg-gray-200 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg sm:mx-0 sm:h-10 sm:w-10">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                           data-tooltip-target="tooltip-bottom" data-tooltip-placement="bottom"
                           stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"/>
                      </svg>
                      <div
                        class="group-hover:visible invisible absolute mt-[-5.5rem] ml-[-5.5rem] pl-4 pr-4 pt-2 pb-2 bg-gray-100 max-w-[30rem] rounded-lg text-center text-highlight shadow  text-sm">
                        <ng-container
                          *ngFor="let passwordPolicy of passwordPolicies;">
                            <label for="{{passwordPolicy.name}}" class="label">
                              {{passwordPolicy.displayName}} {{passwordPolicy.value ? ': ' +  passwordPolicy.value  : ''}}
                            </label>
                        </ng-container>
                      </div>
                    </div>
                  </label>
                  <input class="input-field w-full" id="password"
                         type="password"
                         [(ngModel)]="helperService.getObjectByName(userPassword.fields, 'password').value"
                         name="password" #password="ngModel" required
                         (ngModelChange)="passwordTouched = true"
                         [disabled]="helperService.getObjectByName(userPassword.fields, 'password').readOnly"
                         (keydown)="keyDownFunction($event, passwordForm.valid, 'PASSWORD')"
                         [ngClass]="{'input-field-has-Error': password.invalid && password.touched}"/>
                  <span class="label-error-hint" *ngIf="password.invalid && password.touched">
                   {{'INPUT_FIELD_ERROR.REQUIRED' | translate}}
                 </span>
                </div>
                <div
                  [ngClass]="helperService.getObjectByName(userPassword.fields, 'password').visible? 'shown': 'hidden'">
                  <label for="passwordRepeat" class="label">
                    {{'USERS.PASSWORD_REPEAT' | translate}}*
                  </label>
                  <input class="input-field w-full" id="passwordRepeat"
                         type="password" name="passwordRepeat" required
                         #passwordRepeat="ngModel"
                         [(ngModel)]="passwordRepeatStr"
                         (ngModelChange)="passwordTouched = true;"
                         [disabled]="helperService.getObjectByName(userPassword.fields, 'password').readOnly"
                         (keydown)="keyDownFunction($event, passwordForm.valid, 'PASSWORD')"
                         [ngClass]="{'input-field-has-Error': passwordRepeat.invalid && passwordRepeat.touched}"/>
                  <span class="label-error-hint"
                        *ngIf="checkPasswordValidation()">
                  {{'INPUT_FIELD_ERROR.REQUIRED_EQUAL' | translate}}
                </span>
                </div>
                <div
                  [ngClass]="helperService.getObjectByName(userPassword.fields, 'temporary').visible? 'shown': 'hidden'"
                  class="flex items-center">
                  <input [checked]="helperService.getObjectByName(userPassword.fields, 'temporary').value"
                         (change)="helperService.getObjectByName(userPassword.fields, 'temporary').value = !helperService.getObjectByName(userPassword.fields, 'temporary').value"
                         [disabled]="helperService.getObjectByName(userPassword.fields, 'temporary').readOnly"
                         class="input-checkbox" id="temporary" type="checkbox">
                  <label class="label-checkbox !flex !items-center" for="temporary">{{'USERS.TEMPORARY' | translate}}
                    <div
                      class="group hover:text-highlight hover:bg-gray-200 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg sm:mx-0 sm:h-10 sm:w-10">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                           data-tooltip-target="tooltip-bottom" data-tooltip-placement="bottom"
                           stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"/>
                      </svg>
                      <div
                        class="group-hover:visible invisible absolute mt-[-5.5rem] ml-[-5.5rem] pl-4 pr-4 pt-2 pb-2 bg-gray-100 max-w-[30rem] rounded-lg text-center text-highlight shadow  text-sm">
                        {{'USERS.INFO_TEMPORARY' | translate}}
                      </div>
                    </div>
                  </label>
                </div>
              </ng-container>
            </form>
            <div class="flex mt-3">
              <button class="button-highlight" type="submit"
                      (click)="onSubmit($event, !passwordForm.invalid, 'PASSWORD')"
                      [disabled]="passwordForm.invalid || !((passwordRepeatStr?.length > 0) &&
                        (helperService.getObjectByName(userPassword.fields, 'password').value?.length > 0) &&
                       (passwordRepeatStr?.length == helperService.getObjectByName(userPassword.fields, 'password').value?.length) &&
                       (passwordRepeatStr == helperService.getObjectByName(userPassword.fields, 'password').value))">
              <span *ngIf="loadingPassword" role="status">
              <svg aria-hidden="true" class="mr-2 w-4 h-4 animate-spin fill-highlight" viewBox="0 0 100 101" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"/>
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"/>
              </svg>
              <span class="sr-only">Loading...</span>
            </span>
                {{'USERS.SAVE_PASSWORD' | translate}}
              </button>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="w-full max-lg:mb-4 lg:mr-4 lg:w-2/3 "
           *ngIf="(helperService.getObjectByName(user.fields, 'groupMapping')?.value || helperService.getObjectByName(user.fields, 'firmMapping')?.value) && (showGroupAssignment || showFirmAssignment)">
        <div class="card-div overflow-y-auto max-h-[calc(100vh-7.5rem)]">
          <div id="accordion-flush" data-accordion="open">
            <div *ngIf="helperService.getObjectByName(user.fields, 'groupMapping')?.value && showGroupAssignment"
                 [ngClass]="helperService.getObjectByName(user.fields, 'groupMapping').visible?  'shown': 'hidden'"
                 class="mb-5">
              <!-- Gruppen -->
              <div id="accordion-flush-heading-2">
                <button type="button"
                        (click)="groupMappingShown =!groupMappingShown"
                        class="flex items-center justify-between w-full py-5 text-left text-gray-700 border-b border-gray-200 text-sm"
                        data-accordion-target="#accordion-flush-body-2" aria-expanded="true"
                        aria-controls="accordion-flush-body-2">
                  <div class="flex items-center">
                    <span class="h2-header">{{'USERS.GROUPS' | translate }}</span>
                  </div>
                  <svg [ngClass]="{ 'rotate-180' : groupMappingShown}"
                       class="w-6 h-6 shrink-0 transition-all duration-500" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"></path>
                  </svg>
                </button>
              </div>
              <div id="accordion-flush-body-2"
                   class="transition-all duration-500"
                   [ngClass]="groupMappingShown?  'opacity-100 max-h-80' : 'opacity-0 max-h-0 overflow-hidden'"
                   aria-labelledby="accordion-flush-heading-2">
                <app-compare-lists
                  [showDetails]="true"
                  [showRequiredGroups]="helperService.checkMultipleRequiredGroups(helperService.getObjectByName(user.fields, 'groupMapping').value)"
                  [readOnly]="helperService.getObjectByName(user.fields, 'groupMapping').readOnly"
                  [leftList]="helperService.getObjectByName(user.fields, 'groupMapping').value.active? helperService.getObjectByName(user.fields, 'groupMapping').value.active :  []"
                  [rightList]="helperService.getObjectByName(user.fields, 'groupMapping').value.inactive? helperService.getObjectByName(user.fields, 'groupMapping').value.inactive :  []"
                  [leftTitle]="translate.instant('USERS.ASSIGNED_GROUPS')"
                  [rightTitle]="translate.instant('USERS.POSSIBLE_GROUPS')"
                  [link]="'../../groups/'"
                  (OnUpdatetLists)="helperService.getObjectByName(user.fields, 'groupMapping').value.active = $event.leftList; helperService.getObjectByName(user.fields, 'groupMapping').value.inactive = $event.rightList;">
                </app-compare-lists>
              </div>
            </div>
            <div *ngIf="helperService.getObjectByName(user.fields, 'firmMapping')?.value && showFirmAssignment"
                 [ngClass]="helperService.getObjectByName(user.fields, 'firmMapping').visible? 'shown': 'hidden'"
                 class="mb-5">
              <!-- Standorte -->
              <div id="accordion-flush-heading-1">
                <button type="button"
                        (click)="firmMappingShown =!firmMappingShown"
                        class="flex items-center justify-between w-full py-5 text-left text-gray-700 border-b border-gray-200 text-sm"
                        data-accordion-target="#accordion-flush-body-1" aria-expanded="true"
                        aria-controls="accordion-flush-body-1">
                  <div class="flex items-center">
                    <span class="h2-header">{{'USERS.FIRMS' | translate }}</span>
                  </div>
                  <svg [ngClass]="{ 'rotate-180' : firmMappingShown}"
                       class="w-6 h-6 shrink-0 transition-all duration-500" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"></path>
                  </svg>
                </button>
              </div>
              <div id="accordion-flush-body-1"
                   class="transition-all duration-500"
                   [ngClass]="firmMappingShown? 'opacity-100 max-h-80' : 'opacity-0 max-h-0 overflow-hidden'"
                   aria-labelledby="accordion-flush-heading-1">
                <app-compare-lists
                  [restriction]="'left1'"
                  [readOnly]="helperService.getObjectByName(user.fields, 'firmMapping').readOnly"
                  [leftList]="helperService.getObjectByName(user.fields, 'firmMapping').value.active? helperService.getObjectByName(user.fields, 'firmMapping').value.active :  []"
                  [rightList]="helperService.getObjectByName(user.fields, 'firmMapping').value.inactive? helperService.getObjectByName(user.fields, 'firmMapping').value.inactive :  []"
                  [leftTitle]="translate.instant('USERS.ASSIGNED_FIRMS')"
                  [rightTitle]="translate.instant('USERS.POSSIBLE_FIRMS')"
                  [link]="'../../firms/'"
                  (OnUpdatetLists)="helperService.getObjectByName(user.fields, 'firmMapping').value.active = $event.leftList; helperService.getObjectByName(user.fields, 'firmMapping').value.inactive = $event.rightList;">
                </app-compare-lists>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
