import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {FirmService} from '../../services/firm.service';
import {FirmRestService} from '../../services/firm-rest.service';
import {Location} from '@angular/common';
import {HelperService} from '../../services/helper.service';
import {IObject} from '../../interfaces/field.interface';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-firm-edit',
  templateUrl: './firm-edit.component.html'
})
export class FirmEditComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();


  public selectedType: string = 'DATA';
  public currentId: string;
  public firm: IObject = <IObject>{};
  public loadingRelocationFirms: boolean = true;
  public isNewSubfirm: boolean;
  public allowDeletion: boolean = false;
  public allowCreation: boolean = false;
  public allowRelocation: boolean = false;
  public url: string;
  public showReloactionModal: boolean = false;
  public showDefaultModal: boolean = false;
  public availableFirms: IObject[] = [];
  public loading: boolean = false;


  //accordions
  public clientSelectablesMappingShown: boolean = false;
  public clientMappingShown: boolean = false;
  public groupSelectablesMappingShown: boolean = false;
  public groupMappingShown: boolean = false;
  public defaultGroupMappingShown: boolean = false;
  public userMappingShown: boolean = false;

  constructor(private route: ActivatedRoute,
              public helperService: HelperService,
              public firmService: FirmService,
              private firmRestService: FirmRestService,
              public translate: TranslateService,
              private router: Router,
              private location: Location,
              private toastr: ToastrService,
              public authService: AuthService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.authService.outline$.subscribe(res => {
      if (res) {
        this.allowDeletion = this.helperService.findObjectByName(res, 'SUB_FIRM')?.allowDeletion;
        this.allowCreation = this.helperService.findObjectByName(res, 'SUB_FIRM')?.allowCreation;
        this.allowRelocation = res.some(x => x.name === 'FIRM_RELOCATION');
        if (this.allowRelocation) {
          this.firmRestService.getFirmList().pipe(
            finalize(() => this.loadingRelocationFirms = false)).subscribe({
            next: (firms) => {
              this.availableFirms = firms;
            }
          })
        }
      }
    }));
    this.isNewSubfirm = this.router.url.includes('new-subfirm');
    this.startUp().then();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  async startUp() {
    await this.createOrUpdateCheck().finally(() => {
        this.subscriptions.add(this.firmService.firm$.subscribe(data => {
          if (data) {
            this.firm = data;
          }
        }))
      }
    )
  }

  public changeClientMapping(firm: IObject, event: any): void {
    // wenn aus den selektierbaren Anwendungen ein Eintrag entfernt wird, muss dieser auch in den Anwendungen entfernt werden
    if (event.updatedSide === 'left') {
      this.helperService.getObjectByName(firm.fields, 'clientMapping').value.active.forEach((item, index) => {
        if (item.idOfClient === event.updatedItem.idOfClient) {
          this.helperService.getObjectByName(firm.fields, 'clientMapping').value.active.splice(index, 1);
        }
      })
      this.helperService.getObjectByName(firm.fields, 'clientMapping').value.inactive.forEach((item, index) => {
        if (item.idOfClient === event.updatedItem.idOfClient) {
          this.helperService.getObjectByName(firm.fields, 'clientMapping').value.inactive.splice(index, 1);
        }
      })
    }
    if (event.updatedSide === 'right') {
      this.helperService.getObjectByName(firm.fields, 'clientMapping').value.inactive.push(event.updatedItem);
    }
  }


  public changeSelectedType(selectedType: string): void {
    this.selectedType = selectedType;
  }

  public changeGroupMapping(firm: IObject, event: any, listType: string): void {
    // wenn aus den selektierbaren Gruppen ein Eintrag entfernt wird, muss dieser auch in den Gruppen und Standardgruppen entfernt werden
    if (listType === 'groupSelectablesMapping') {
      if (event.updatedSide === 'left') {
        this.helperService.getObjectByName(firm.fields, 'groupMapping').value.active.forEach((item, index) => {
          if (item.id === event.updatedItem.id) {
            this.helperService.getObjectByName(firm.fields, 'groupMapping').value.active.splice(index, 1);
          }
        })
        this.helperService.getObjectByName(firm.fields, 'groupMapping').value.inactive.forEach((item, index) => {
          if (item.id === event.updatedItem.id) {
            this.helperService.getObjectByName(firm.fields, 'groupMapping').value.inactive.splice(index, 1);
          }
        })

        this.helperService.getObjectByName(firm.fields, 'defaultGroupMapping').value.active.forEach((item, index) => {
          if (item.id === event.updatedItem.id) {
            this.helperService.getObjectByName(firm.fields, 'defaultGroupMapping').value.active.splice(index, 1);
          }
        })
        this.helperService.getObjectByName(firm.fields, 'defaultGroupMapping').value.inactive.forEach((item, index) => {
          if (item.id === event.updatedItem.id) {
            this.helperService.getObjectByName(firm.fields, 'defaultGroupMapping').value.inactive.splice(index, 1);
          }
        })
      }
      if (event.updatedSide === 'right') {
        this.helperService.getObjectByName(firm.fields, 'groupMapping').value.inactive.push(event.updatedItem);
      }
    }

    // wenn aus den Gruppen ein Eintrag entfernt wird, muss dieser auch in den Standardgruppen entfernt werden
    if (listType === 'groupMapping') {
      if (event.updatedSide === 'left') {
        this.helperService.getObjectByName(firm.fields, 'defaultGroupMapping').value.active.forEach((item, index) => {
          if (item.id === event.updatedItem.id) {
            this.helperService.getObjectByName(firm.fields, 'defaultGroupMapping').value.active.splice(index, 1);
          }
        })
        this.helperService.getObjectByName(firm.fields, 'defaultGroupMapping').value.inactive.forEach((item, index) => {
          if (item.id === event.updatedItem.id) {
            this.helperService.getObjectByName(firm.fields, 'defaultGroupMapping').value.inactive.splice(index, 1);
          }
        })
      }
      if (event.updatedSide === 'right') {
        this.helperService.getObjectByName(firm.fields, 'defaultGroupMapping').value.inactive.push(event.updatedItem);
      }
    }
  }


  public createOrUpdateCheck(): Promise<boolean> {
    return new Promise((resolve) => {
      this.route.params.subscribe(params => {
        this.currentId = params.id
        if (this.currentId && !this.isNewSubfirm) {
          this.firmService.getFirmById(this.currentId);
          resolve(true);
        } else {
          this.firmService.getFirmStructure();
          resolve(false);
        }
      })
    });
  }


  public keyDownFunction(event: KeyboardEvent, valid: boolean): void {
    if (valid && event.keyCode === 13) {
      this.submitData();
    }
  }

  public onSubmit(event: Event, valid: boolean): void {
    event.preventDefault();
    if (valid) {
      this.submitData();
    }
  }

  public relocate(id: number): void {
    this.firmRestService.relocateFirm(this.helperService.getObjectByName(this.firm.fields, 'id').value, id).subscribe(
      {
        next: (res) => {
          this.firm = res;
          this.currentId = this.helperService.getObjectByName(res.fields, 'id').value;
          this.toastr.success(this.translate.instant("MESSAGES.SUCCESS.DATA"))
        }
        ,
        error: () => {
          this.toastr.error(this.translate.instant("MESSAGES.ERROR.DATA_SAVE"))
        }
      })
  }

  public updateClientToParent(): void {
    this.firmRestService.resetToParentFirm(this.firm as IObject).subscribe(
      {
        next: (res) => {
          this.firm = res;
          this.currentId = this.helperService.getObjectByName(res.fields, 'id').value;
          this.toastr.success(this.translate.instant("MESSAGES.SUCCESS.DATA"))
        },
        error: () => {
          this.toastr.error(this.translate.instant("MESSAGES.ERROR.DATA_SAVE"))
        }
      })
  }

  public setResponseMessage(error): void {
    if (error.error.status == 409) {
      if (error.error.message == 'firm with this name already exists') {
        this.toastr.error(this.translate.instant("MESSAGES.ERROR.FIRMNAME_EXISTS"))
      } else if (error.error.message == 'firm with this debitorNo already exists') {
        this.toastr.error(this.translate.instant("MESSAGES.ERROR.DEBITORNO_EXISTS"))
      } else {
        this.toastr.error(this.translate.instant("MESSAGES.ERROR.DATA_SAVE"))
      }
    } else if (error.error.status == 400) {
      if (error.error.message == 'firm name is empty') {
        this.toastr.error(this.translate.instant("MESSAGES.ERROR.FIRMNAME_EMPTY"))
      } else if (error.error.message == 'debitorNo name is empty') {
        this.toastr.error(this.translate.instant("MESSAGES.ERROR.DEBITORNO_EMPTY"))
      } else if (error.error.message == 'mandatory unique group constraint is violated') {
        this.toastr.error(this.translate.instant("MESSAGES.ERROR.DUTY_GROUP"))
      } else {
        this.toastr.error(this.translate.instant("MESSAGES.ERROR.DATA_SAVE"))
      }
    } else {
      this.toastr.error(this.translate.instant("MESSAGES.ERROR.DATA_SAVE"))
    }
  }

  public submitData(): void {
    this.loading = true;
    this.isNewSubfirm ? setTimeout(() => {
      this.firmRestService.createSubfirm(this.firm as IObject, this.currentId).pipe(finalize(() => this.loading = false))
        .subscribe({
            next: (response) => {
              this.toastr.success(this.translate.instant("MESSAGES.SUCCESS.DATA"))
              if (response) {
                this.firm = response
                this.currentId = this.helperService.getObjectByName(response.fields, 'id').value;
                this.location.replaceState('/firms/' + this.currentId);
                this.isNewSubfirm = false;
              }
            },
            error: (error) => {
              this.setResponseMessage(error);
            }
          }
        )
    }, 250) : setTimeout(() => {
      let req;
      req = this.currentId ? this.firmRestService.updateFirm(this.firm as IObject) : this.firmRestService.createFirm(this.firm as IObject)
      req.pipe(finalize(() => this.loading = false)).subscribe(
        {
          next: (res) => {
            this.firm = res;
            this.currentId = this.helperService.getObjectByName(res.fields, 'id').value;
            this.location.replaceState('/firms/' + this.currentId);
            this.toastr.success(this.translate.instant("MESSAGES.SUCCESS.DATA"))
          },
          error: (error) => {
            this.setResponseMessage(error);
          }
        }
      )
    }, 250);
  }
}
