declare var window: any;

export class DynamicEnvironment {
  public get realmConfig(): string {
    return this.getValueFromLocalStorage('REALM_NAME', window.config.REALM_NAME);
  }

  public set realmConfig(des: string) {
    localStorage.setItem('REALM_NAME', des);
  }

  public get fallBackLanguage(): string {
    return this.getValueFromLocalStorage('FALLBACK_LOCALE', window.config.FALLBACK_LOCALE);
  }

  public get showRealmName(): boolean {
    const storedValue = localStorage.getItem('SHOW_REALM_NAME');
    if (storedValue !== null) {
      return storedValue.toLowerCase() === 'true';
    }
    return window.config.SHOW_REALM_NAME;
  }

  public set showRealmName(show: boolean) {
    localStorage.setItem('SHOW_REALM_NAME', show.toString());
  }

  public get realmNames(): string[] {
    let obj = window.config;
    const result: string[] = [];

    for (const key in obj) {
      if (key.startsWith("REALM_NAME_")) {
        result.push(obj[key] as string);
      }
    }
    return result;
  }

  public get externalKeycloakUrl(): string {
    if(window.config == undefined) {
      return undefined
    }
    return window.config.KEYCLOAK_EXTERNAL_URL;
  }

  public get showImpersonation(): boolean {
    return window.config.SHOW_IMPERSONATION;
  }

  public get impersonationRedirectUrl(): string {
    return window.config.IMPERSONATION_REDIRECT_URL
  }

  private getValueFromLocalStorage(key: string, defaultValue: any): any {
    const storedValue = localStorage.getItem(key);
    if (storedValue !== null) {
      return storedValue;
    }
    return defaultValue;
  }
}
