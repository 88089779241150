<ng-container *ngIf="(userService.showErrorPageUsers$ | async) === 'ERROR_USER_ROLES'">
  <app-default-error-page></app-default-error-page>
</ng-container>

<ng-container *ngIf="(userService.showErrorPageUsers$ | async) === 'LOADING'">
  <app-default-loading-page></app-default-loading-page>
</ng-container>

<ng-container *ngIf="(userService.showErrorPageUsers$ | async) === 'CONTENT_USER_ROLES'">
  <div class="card-header-div">
    <h1 class="h1-header mr-4 flex-1 flex items-center">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="w-6 h-6 cursor-pointer mr-1" (click)="helperService.locationBack()">
        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
      </svg>
      <span>
        {{'USERS.HEADER' | translate}} &nbsp;</span>
      <span *ngIf="username">- {{username}}</span>
    </h1>
  </div>
  <form #userForm="ngForm">
    <div class="p-5">
      <div class="w-1/3">
        <div class="card-div overflow-y-auto max-h-[calc(100vh-16.75rem)]">
          <h2 class="h2-header font-bold pb-[0.625rem]">
            {{'USERS.VIEW_ROLES' | translate}}
          </h2>
          <app-user-roles [userRoles]="userRoles"></app-user-roles>
        </div>
      </div>
    </div>
  </form>
</ng-container>
