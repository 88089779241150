import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-default-error-page',
  templateUrl: './default-error-page.component.html'
})
export class DefaultErrorPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
