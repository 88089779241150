<div *ngFor="let item of userRoles; let l= last;"
     class="flex pb-2"
     [ngClass]="{'mb-3 border-b': !l}">
  <div class="w-4/12">
    {{item.name}}
  </div>
  <div class="w-8/12">
    <div *ngIf="item?.roles && item.roles.length > 0" class="text-gray-700">
      {{'USERS.ROLES' | translate}}:
    </div>
    <div *ngFor="let role of item.roles" class="flex items-center py-1">
      <input [checked]="true" type="checkbox"
             [disabled]="true"
             class="input-checkbox" id="{{role.id}}">
      <label class="label-checkbox" for="{{role.id}}">{{role.name}}</label>
    </div>
    <div *ngIf="item?.groups && item.groups.length > 0" class="mb-2 text-secondary">
      {{'USERS.GROUPS' | translate}}:
    </div>
    <div *ngFor="let group of item.groups" class="flex items-center py-1">
      <input [checked]="true" type="checkbox"
             [disabled]="true"
             class="input-checkbox" id="{{group.id}}">
      <label class="label-checkbox" for="{{group.id}}">{{group.name}}</label>
    </div>
  </div>
</div>

<ng-container *ngIf="!userRoles || userRoles.length== 0">
  <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 m-4 text-sm" role="alert">
    <p>{{'GENERAL.EMPTY' | translate}}</p>
  </div>
</ng-container>
