<ng-container *ngIf="(clientService.showErrorPageClients$ | async) === 'ERROR_CLIENTS'">
  <app-default-error-page></app-default-error-page>
</ng-container>

<ng-container *ngIf="(clientService.showErrorPageClients$ | async) === 'LOADING'">
  <app-default-loading-page></app-default-loading-page>
</ng-container>

<ng-container *ngIf="(clientService.showErrorPageClients$ | async) === 'CONTENT_CLIENTS'">
  <div class="card-header-div">
    <h1 class="h1-header mr-4 flex-1">
      {{'CLIENTS.HEADER' | translate}}
    </h1>
    <div class="flex flex-1 justify-end gap-x-2">
      <button *ngIf="allowCreation" class="button-highlight" routerLink="new">{{'GENERAL.APPEND' | translate}}</button>
    </div>
  </div>

  <ng-container *ngIf="clients && clients.length >0">
    <div class="p-5">
      <div class="card-div">
        <div class="table-header-row">
          <div class="w-10/12 table-column">
            <h2> {{'GENERAL.LIST.NAME' | translate}}</h2>
          </div>
          <div class="w-2/12 table-column justify-center">
          </div>
          <div class="w-[5px]"></div>
        </div>
        <div class="overflow-y-auto max-h-[calc(100vh-13rem)]">
          <div *ngFor="let client of clients; let last = last"
               class="table-row cursor-pointer border-b"
               [ngClass]="{'rounded-b-md' : last}">
            <div class="w-10/12 table-column"
                 routerLink="{{(helperService.getObjectByName(client.fields, 'idOfClient')).value}}">
              {{(helperService.getObjectByName(client.fields, 'clientName')).value}}
            </div>
            <div class="w-2/12 table-column justify-end">
              <div
                class="hover:text-highlight hover:bg-gray-200 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg sm:mx-0 sm:h-10 sm:w-10">
                <svg routerLink="{{(helperService.getObjectByName(client.fields, 'idOfClient')).value}}"
                     xmlns="http://www.w3.org/2000/svg"
                     class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"/>
                </svg>
              </div>
              <div *ngIf="allowDeletion"
                   class="hover:text-sso-red hover:bg-red-100 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-lg sm:mx-0 sm:h-10 sm:w-10">
                <svg (click)="selectedClient = client; showDeleteModal = true" xmlns="http://www.w3.org/2000/svg"
                     class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!clients || clients.length== 0">
    <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 m-4 text-sm" role="alert">
      <p>{{'GENERAL.EMPTY' | translate}}</p>
    </div>
  </ng-container>

  <ng-container *ngIf="showDeleteModal">
    <app-default-modal
      (toggleModal)="showDeleteModal = false"
      (returnObject$)="deleteClient((helperService.getObjectByName(selectedClient.fields, 'idOfClient')).value)"
      [textObject]="{header: 'CLIENTS.DELETE_CLIENT', text: 'CLIENTS.DELETE_CLIENT_TEXT' | translate: {'name': (helperService.getObjectByName(selectedClient.fields, 'clientName')).value}}">
    </app-default-modal>
  </ng-container>

</ng-container>
