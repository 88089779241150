import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AdminRestService} from "../../services/admin-rest.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html'
})
export class ExportModalComponent implements OnInit {
  @Output() toggleModal = new EventEmitter<boolean>();

  constructor(private adminRestService: AdminRestService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
  }


  public exportModalClose(): void {
    this.toggleModal.emit(false);
  }


  public downloadFile(type: string): void {
    this.adminRestService.downloadFile(type).subscribe(
      {
        next: (res) => {
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.download = type + '.csv';
          a.href = URL.createObjectURL(res);
          a.click();
          document.body.removeChild(a);
        },
        error: () => {
          this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA_SAVE'));
        }
      })

  }


}
