import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {environment} from '../../environments/environment';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ThemingService} from '../services/theming.service';
import {AdminRestService} from '../services/admin-rest.service';
import {DomSanitizer} from '@angular/platform-browser';
import {CookieService} from 'ngx-cookie-service';
import {HelperService} from '../services/helper.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html'
})
export class RootComponent implements OnInit {
  public realmName = environment.realmConfig.toUpperCase();
  public showRealm = environment.showRealmName;
  public Subscriptions: Subscription[] = [];
  public outline: any;
  public helpLink: any;
  public showImportModal = false;
  public showExportModal = false;
  public realmNames = environment.realmNames;
  public selectedRealm = environment.realmConfig;
  public showRealmSelect = false;

  constructor(public authService: AuthService,
              private sanitizer: DomSanitizer,
              private helperService: HelperService,
              private translate: TranslateService,
              private cookieService: CookieService,
              private themingService: ThemingService,
              public adminRestService: AdminRestService,
              private toastr: ToastrService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.authService.userData$.subscribe(userdata => {
        if (userdata) {
          let theme = this.toLowerCaseIfDefined(userdata?.pseudoRealm)
            || this.toLowerCaseIfDefined(environment.realmConfig)
            || 'default';
          this.themingService.setTheme(theme);
          this.cookieService.set('username', userdata.username);
          if(userdata.realm === 'master') {
            this.realmNames.push('master')
            this.showRealmSelect = true;
          }
        }
      }
    );

    this.Subscriptions.push(this.authService.getOutline().subscribe(
      {
        next: (response) => {
          this.authService.username$.next(this.cookieService.get('username'));
          this.authService.outline$.next(response);
        },
        error: () => {
          this.toastr.error(this.translate.instant('MESSAGES.ERROR.AUTH'), this.translate.instant('MESSAGES.ERROR.AUTH_TITLE'));
        }
      }
    ));

    this.Subscriptions.push(this.authService.outline$.subscribe(data => {
      if (data) {
        this.outline = data;
        if (!this.checkPermissionOutline(this.router.url.split('/')[1].toUpperCase())) {
          this.router.navigate(['users']).then()
        }
        if (this.checkPermissionOutline('HELP')) {
          this.getHelpLink();
        }
      }
    }));

    this.authService.username$.next(this.cookieService.get('username'));
  }

  public onRealmChange(selectedRealm: string): void {
    environment.realmConfig = selectedRealm;
    const url = this.router.url;
    const segments = url.split('/');
    if(segments.length > 2) {
      this.router.navigate([segments[1]]).then(() => window.location.reload());
    } else {
      window.location.reload();
    }


  }

  public toLowerCaseIfDefined(value: string | undefined): string | undefined {
    return value !== undefined ? value.toLowerCase() : undefined;
  }


  public checkPermissionOutline(search: string): boolean {

    if (this.outline) {
      return this.outline.some(x => x.name === search);
    } else return false;
  }


  ngOnDestroy(): void {
    if (this.Subscriptions) {
      this.Subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

  public getHelpLink(): void {
    this.adminRestService.getHelpLink().subscribe({
      next: (res) => {
        this.helpLink = res
      },
      error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA'));
      }
    })
  }

  public toggleImportModal(): void {
    this.showImportModal = !this.showImportModal;
  }

  public toggleExportModal(): void {
    this.showExportModal = !this.showExportModal;
  }

  public openHelp(): void {
    window.open(this.helpLink);
  }

}
