import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../services/user.service';
import {UserRestService} from '../../services/user-rest.service';
import {Location} from '@angular/common';
import {HelperService} from '../../services/helper.service';
import {FirmRestService} from '../../services/firm-rest.service';
import {IObject} from '../../interfaces/field.interface';
import {finalize} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {IUserRoles} from "../../interfaces/user.roles";
import {IPasswordPolicy} from 'src/app/interfaces/user';
import {AuthService} from 'src/app/auth/auth.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html'
})
export class UserEditComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  public selectedType: string = 'DATA';
  public currentId: string;
  public firmMappingShown: boolean = false;
  public groupMappingShown: boolean = false;
  public rolesLoading: boolean = false;
  public loading: boolean = false;
  public loadingPassword: boolean = false;
  public user: IObject = <IObject>{};
  public availableFirms: IObject[] = [];
  public assignedFirm: string;
  public showAssignedFirm: boolean = true;
  public userPassword: IObject = <IObject>{};
  public passwordRepeatStr: string;
  public userRoles: IUserRoles [];
  public passwordPolicies: IPasswordPolicy[];
  public firmSearchDisabled: boolean = true;
  public firmSearchPlaceholder: string;
  public passwordTouched: boolean = false;
  public showFirmAssignment: boolean = true;
  public showGroupAssignment: boolean = true;
  public allowImpersonation: boolean = false;

  constructor(private route: ActivatedRoute,
              public userService: UserService,
              private userRestService: UserRestService,
              public translate: TranslateService,
              public helperService: HelperService,
              private firmRestService: FirmRestService,
              private toastr: ToastrService,
              private location: Location,
              private authService: AuthService) {

  }

  ngOnInit(): void {
    this.translate.get('GENERAL.PLEASE_WAIT').subscribe(data => this.firmSearchPlaceholder = data);
    if(environment.realmConfig === "master") {
      this.showFirmAssignment = false;
      this.showGroupAssignment = false;
    }

    this.subscriptions.add(
      this.authService.outline$.subscribe((res) => {
        if (res) {
          this.allowImpersonation = res.some((x) => x.name === 'IMPERSONATION') && environment.showImpersonation;
        }
      })
    );

    this.startUp().then();
    if (this.currentId) {
      this.getRoles(this.currentId);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async startUp() {

    this.subscriptions.add(this.userRestService.getPasswordPolicy().subscribe(data => {
      this.passwordPolicies = data
    }))

    await this.createOrUpdateCheck().finally(() => {
        this.subscriptions.add(this.userService.user$.subscribe(data => {
          if (data) {
            this.user = data;
            this.subscriptions.add(this.route
              .queryParams
              .subscribe(params => {
                // Nutzer kann aus einer Firma erstellt werden, dieser hat dann auch diese Firma zugewiesen
                if (params && params.assignedFirm) {
                  this.showAssignedFirm = false;
                  this.helperService.getObjectByName(this.user.fields, 'firms').value.push(params.assignedFirm)
                }
              }));

            this.userRestService.getPasswordStructure(this.helperService.getObjectByName(this.user.fields, 'id')?.value || 1)
              .subscribe({next: (response) => this.userPassword = response});
          }
        }))
      }
    )
  }

  public changeSelectedType(selectedType: string): void {
    this.selectedType = selectedType;
  }

  public checkPasswordValidation(): boolean {
    let result = false;
    if (this.passwordTouched) {
      result = !((this.passwordRepeatStr?.length > 0) &&
        (this.helperService.getObjectByName(this.userPassword.fields, 'password').value?.length > 0) &&
        (this.passwordRepeatStr?.length == this.helperService.getObjectByName(this.userPassword.fields, 'password').value?.length) &&
        (this.passwordRepeatStr == this.helperService.getObjectByName(this.userPassword.fields, 'password').value));
    }
    return result;
  }

  public createOrUpdateCheck(): Promise<boolean> {
    return new Promise((resolve) => {
      this.route.params.subscribe(params => {
        this.currentId = params.id
        if (this.currentId) {
          this.userService.getUserById(this.currentId);
          resolve(true);
        } else {
          this.userService.getUserStructure();
          this.firmRestService.getFirmList().subscribe(
            {
              next: (firms) => {
                this.firmSearchDisabled = false;
                this.firmSearchPlaceholder = this.translate.instant('USERS.FIRM_SEARCH');
                this.availableFirms = firms;
                if (this.availableFirms.length === 1) {
                  this.assignedFirm = this.helperService.getObjectByName(firms[0].fields, 'name').value + ' / ' + this.helperService.getObjectByName(firms[0].fields, 'id').value;
                }
              },
              error: () => this.toastr.error(this.translate.instant('MESSAGES.ERROR.GET_DEPENDENT_RESOURCES'))
            })
          resolve(false);
        }
      })
    });
  }


  public getRoles(id: string): void {
    this.rolesLoading = true;
    this.userRestService.getUserRolesById(id).pipe(
      finalize(() => this.rolesLoading = false)).subscribe({
      next: (res) => {
        this.userRoles = res;
      }, error: () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR.GET_DEPENDENT_RESOURCES'));
      }
    })
  }

  public keyDownFunction(event: KeyboardEvent, valid: boolean, type: string): void {
    if (valid && event.keyCode === 13) {
      switch (type) {
        case 'PASSWORD':
          this.submitPassword();
          break;
        case 'DATA':
          this.submitData();
          break;
      }
    }
  }


  public onSubmit(event: Event, valid: boolean, type: string): void {
    event.preventDefault();
    if (valid) {
      switch (type) {
        case 'PASSWORD':
          this.submitPassword();
          break;
        case 'DATA':
          this.submitData();
          break;
      }
    }
  }

  public submitData(): void {
    this.loading = true;
    let request;
    if (this.currentId) {
      request = this.userRestService.updateUser(this.user as IObject);
    } else {
      // Überprüfung, ob das Feld angezeigt wurde, dann id in Array pushen
      if (this.showAssignedFirm) {
        const id = this.assignedFirm.substring(this.assignedFirm.indexOf('/') + 2);
        this.helperService.getObjectByName(this.user.fields, 'firms').value.push(id);
      }
      request = this.userRestService.createUser(this.user as IObject);
    }
    request.pipe(finalize(() => this.loading = false)).subscribe({
      next: (response) => {
        if (response) {
          this.user = response;
          this.currentId = this.helperService.getObjectByName(response.fields, 'id').value;
          this.getRoles(this.currentId);
          this.location.replaceState('/users/' + this.currentId);
        }
        this.toastr.success(this.translate.instant('MESSAGES.SUCCESS.DATA'));
      },
      error: (error) => {
        if (error.error.message == 'mandatory unique group constraint is violated') {
          this.toastr.error(this.translate.instant("MESSAGES.ERROR.DUTY_GROUP"))
        } else {
          this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA_SAVE'))
        }
      }
    })
  }

  public submitPassword(): void {
    this.loadingPassword = true;
    let request;
    request = this.userRestService.setPassword(this.userPassword as IObject, this.currentId);
    request.pipe(finalize(() => this.loadingPassword = false)).subscribe({
      next: () => {
        this.toastr.success(this.translate.instant('MESSAGES.SUCCESS.DATA'));
      },
      error: (error) => {
        if (error.status == 400) {
          this.toastr.error(this.translate.instant('MESSAGES.ERROR.PASSWORD_SAVE'));
        } else {
          this.toastr.error(this.translate.instant('MESSAGES.ERROR.DATA_SAVE'));
        }
      }
    })
  }

  public impersonate() {
    this.userService.impersonate(this.currentId, environment.realmConfig)
  }

}
